<li [attr.class]="config?.rootClass" *ngIf="!config?.settings?.stright">
  <!-- <label class="filter__label">All Models</label> -->
  <div [attr.class]="config?.actionContainerClass" [class.active]="active">
    <div class="filter__trigger" (click)="onclickevent($event)">
      <ng-container *ngIf="active">
        <i class="fal fa-search"></i>
        <input type="text" class="field__label" (ngModelChange)="onSearch($event)" [(ngModel)]="search" />
      </ng-container>
      <ng-container *ngIf="!active">
        <i [attr.class]="config?.icons?.leftIcon"></i>
        <span class="field__label">{{ getTitle() }}</span>
      </ng-container>
      <div class="field__icons">
        <span *ngIf="active && search && search.length > 0" style="margin-right: 10px">
          <i class="fal fa-backspace" (click)="search = ''"></i>
        </span>
        <span *ngIf="active">
          <i class="fal fa-times" (click)="close($event)"></i>
        </span>
        <i *ngIf="!active" class="far fa-angle-down"></i>
      </div>
    </div>
    <ng-container *ngIf="!config?.settings?.bigModal && render">
      <ng-container *ngTemplateOutlet="listtemplate; context: ctx"> </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="config?.settings?.bigModal && render">
    <ng-container *ngTemplateOutlet="listtemplate; context: ctx"> </ng-container>
  </ng-container>
</li>

<!-- Straight Tree -->
<li [attr.class]="config?.rootClass" *ngIf="config?.settings?.stright && render">
  <!-- <label class="filter__label">All Models</label> -->
  <div class="filter-field">
    <div class="filter__trigger" (click)="onclickevent($event)">
      <label class="field__label">{{ getTitle() }}</label>
      <div class="field__icons">
        <span class="icons__active"> <i class="fal fa-pencil"></i></span>
        <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
        <i class="far fa-angle-down"></i>
      </div>
    </div>
    <div class="filter-dropdown dropdown--list" [class.active]="active">
      <ul [attr.class]="config?.listUlClass" style="overflow-x: auto;">
        <li class="tree-list_item">
          <app-gbf-list
            (onitemclick)="onItemClicked($event)"
            (onbreadcrumclick)="onListBreadcrumClick($event)"
            [config]="config"
            [from]="'tree'"
            [title]="'Title...'"
            [breadcrums]="breadcrums"
            [listRootClassName]="'filter__list'"
            [values]="activatedItems"
            [active]="active"
            [allexceptolderDisabled]="allexceptolderDisabled"
          >
          </app-gbf-list>
        </li>
      </ul>
    </div>
  </div>
</li>

<ng-template #listtemplate let-config="config" let-active="active" let-search="search">
  <div [attr.class]="config?.listContainerClass" [ngClass]="{ active: active }">
    <rf-breadcrumb
      (onItemClicked)="onNestedBreadcrumClick($event)"
      *ngIf="!config?.settings?.multi && search?.length < 1"
      [model]="breadcrums"
      [styleClass]="'tree-breadcrumbs'"
    >
    </rf-breadcrumb>
    <ul [attr.class]="config?.listUlClass" style="overflow-x: auto;" *ngIf="search?.length == 0">
      <ng-container *ngFor="let kat of Object.keys(activeTree); let i = index">
        <li
          class="tree-list_item"
          [ngStyle]="{
            display: config?.treeListConfig && config?.treeListConfig[i]?.display === false ? 'none' : 'table-cell'
          }"
          *ngIf="activeTree[kat].length > 0"
        >
          <app-gbf-list
            [pindex]="i"
            (onitemclick)="onItemClicked($event)"
            [settings]="
              config?.treeListConfig && config?.treeListConfig[i] ? config?.treeListConfig[i] : config?.settings
            "
            [config]="config"
            [configName]="config.name"
            [from]="'tree'"
            [listRootClassName]="getActiveLevel() == kat ? 'level-selected filter__list' : 'filter__list'"
            [title]="
              config?.treeTitles && config?.treeTitles.length > 0 && config?.treeTitles[i] ? config?.treeTitles[i] : ''
            "
            [values]="getvalues(kat)"
            [active]="true"
            [allexceptolderDisabled]="allexceptolderDisabled"
          >
          </app-gbf-list>
        </li>
      </ng-container>
      <ng-container *ngIf="config?.settings.suggestions && gbfService.show_suggestions">
        <li class="tree-list_item">
          <app-gbf-list
            (onitemclick)="onSuggestionClicked($event)"
            [settings]="config?.settings"
            [config]="config"
            [from]="'tree'"
            [configName]="'org_suggestions'"
            [listRootClassName]="'filter__list'"
            [title]="'Suggestion'"
            [values]="gbfService.suggestions"
            [active]="true"
            [allexceptolderDisabled]="allexceptolderDisabled"
          >
          </app-gbf-list>
        </li>
      </ng-container>
    </ul>
    <div *ngIf="search.length > 0">
      <ng-container *ngIf="searchLoader">
        <li style="text-align: center; font-weight: bold; margin-top: 12px">
          <div class="temp-loader">
            <a class="handler more" href="javascript:void(0)">
              <p-progressSpinner [style]="{ width: '25px', height: '25px' }" strokeWidth="9" animationDuration=".5s">
              </p-progressSpinner>
            </a>
          </div>
        </li>
      </ng-container>
      <ng-container *ngIf="searchItems?.length != 0 && !searchLoader">
        <cvp-scrollPanel-ul [style]="{ width: '100%', height: searchItems.length > 4 ? '200px' : 'auto' }">
          <li *ngFor="let items of searchItems">
            <rf-breadcrumb [styleClass]="'tree-breadcrumbs'" [model]="items" (click)="selectSearchItem(items)">
            </rf-breadcrumb>
          </li>
        </cvp-scrollPanel-ul>
      </ng-container>
      <ng-container *ngIf="search.length > 0 && searchItems?.length == 0 && !searchLoader">
        <li style="text-align: center; font-weight: bold; margin-top: 12px">{{ 'No record found.' | translate }}</li>
      </ng-container>
    </div>
    <div class="dropdown__footer" *ngIf="config?.settings?.listDoneButton">
      <a class="btn" (click)="done($event); $event.stopPropagation()">{{ 'Done' | translate }}</a>
    </div>
  </div>
</ng-template>
