import { RestMessage } from '@app/core/types';
import { tap, map, catchError, timeout, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Survey, SurveListHotAlert } from '../../../reporting/types/survey';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FiltersService } from '@app/shared/services/filters.service';
import { Logger, AuthenticationService } from '@app/core';
// import { ContactStatisticsService } from '../contact-statistics/contact-statistics.service';
import { HelperService } from '@clearviewshared/services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ISurveyListResponse, SurveyListSummary } from './types/ISurveyListResponse';
import { filterQueryGenerator, mapDynamicColumns, responseTransformation } from '@app/core/helpers';
import { BreadCrumbService } from '@app/shared/services/breadcrumb.service';
import { ContactStatisticsService } from '../../../reporting/contact-statistics/contact-statistics.service';

const log = new Logger('SurveyListReportService');
@Injectable({ providedIn: 'root' })
export class SurveyListService {
  user = this.authService.getUser();

  surveys: Survey[] = [];
  filter: any;
  filterShow: boolean = true;
  headers: HttpHeaders;
  options: any;
  blockFirstExecute: boolean = false;
  totalNoRecords: number = 0;
  cols: any[] = [];
  contactStatsFilter: any;
  contactStatsClickThrough: string;

  reportingUrl = '/surveylist';
  constructor(
    private httpClient: HttpClient,
    private breadcrumbService: BreadCrumbService,
    private filtersService: FiltersService,
    private helperService: HelperService,
    private authService: AuthenticationService,
    private contactStaticsService: ContactStatisticsService,
    public translateService: TranslateService
  ) {}

  getClickContactStatsThroughForSurveyList() {
    if (this.breadcrumbService?.stack[1]?.routerLink === '/insights/reports/statistics-v2')
      this.contactStatsFilter = this.contactStaticsService.getFilter(true);
    else this.contactStatsFilter = this.contactStaticsService.getFilter();
    this.contactStatsClickThrough = this.contactStatsFilter?.ClickThroughFilter?.url_param ?? '';
    this.contactStatsClickThrough = this.contactStatsClickThrough != '' ? `/${this.contactStatsClickThrough}` : '/all';
  }

  async exportCall(
    PageNo: number,
    Pagesize: number,
    IsPagination: boolean,
    isDeleted = false,
    searchText = '',
    selectedValue = '',
    npsFilter: any = '',
    processView: any = 'all',
    searchKey = 'all'
  ) {
    let filterQuery = filterQueryGenerator(
      {
        Value: selectedValue,
        Text: searchText,
        Formatter: '',
      },
      this.filtersService
    );
    let ContactFilter = this.contactStaticsService.getFilter();
    let filter = this.filtersService.getFilterObject();
    const resultingProcessView = this.getProcessView(filter.SurveyType ? filter.SurveyType : 'All');
    filter.Filters = filterQuery;
    filter.Fields = '';
    filter.PageNo = PageNo;
    filter.PageSize = Pagesize;
    //filter.Channels = Channel === 'All' ? filter.Channels : Channel;

    let SuppryFilter: any = {};
    SuppryFilter['ReportingModel'] = filter;
    if (ContactFilter && ContactFilter['ClickThroughFilter'] && ContactFilter.ClickThroughFilter['greater']) {
      SuppryFilter['ClickThroughFilter'] = ContactFilter['ClickThroughFilter'];
    } else if (npsFilter !== null && npsFilter.trim() !== '') {
      const Npsfilter = { NpsFilter: npsFilter };
      SuppryFilter['ClickThroughFilter'] = Npsfilter;
    } else {
      SuppryFilter['ClickThroughFilter'] = null;
    }

    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.options = {
      observe: 'response',
      headers: this.headers,
      responseType: 'blob',
    };
    const language = localStorage.getItem('language');
    this.getClickContactStatsThroughForSurveyList();
    // const url = `${this.reportingUrl}/${PageNo}/${Pagesize}/${IsPagination}/${CustomSurveyParam}/${isDeleted}/${processView}${this.contactStatsClickThrough}?languageCode=${language}`;
    const url = `${this.reportingUrl}/SurveyResponseExport/${isDeleted}/${resultingProcessView}/${IsPagination}/${searchKey}/${language}`;
    const filename = isDeleted
      ? this.translateService.instant('Deleted Responses')
      : this.translateService.instant('Responses');

    return await this.httpClient
      .post<RestMessage>(url, SuppryFilter, this.options)
      .pipe(
        timeout(360000000),
        catchError((err: any) => {
          // console.log("timeout error", err)
          return null;
        })
      )
      .subscribe((response) => {
        if (!IsPagination && this.totalNoRecords > 10000) {
          //this.helperService.exportCSVFile(response, filename);
          this.helperService.exportExelFile(response, filename);
        } else {
          this.helperService.exportExelFile(response, filename);
        }
      });
  }

  updateFollowUpSurvey(HotAlertId: string, ResponseId: string) {
    let ContactFilter = this.contactStaticsService.getFilter();
    let filter = this.filtersService.getFilterObject();
    let SuppryFilter: any = {};
    SuppryFilter['ReportingModel'] = filter;
    if (ContactFilter && ContactFilter['ClickThroughFilter'] && ContactFilter.ClickThroughFilter['greater']) {
      SuppryFilter['ClickThroughFilter'] = ContactFilter['ClickThroughFilter'];
    } else {
      SuppryFilter['ClickThroughFilter'] = null;
    }

    const url = `${this.reportingUrl}/${HotAlertId}/${ResponseId}`;
    return this.httpClient.put<RestMessage>(url, SuppryFilter).pipe(
      tap((_: any) => log.debug('in tap getData')),
      map((res: any) => {
        return res;
      })
    );
  }

  surveyFilter(SearchFilter = '', SearchField = '') {
    let filter = this.filtersService.getFilterObject();
    filter.Filters = '/' + SearchFilter + '/i';
    filter.Fields = SearchField;
    return { filter };
  }

  getSurveyListReport(
    PageNo: number,
    Pagesize: number,
    isDeleted = false,
    searchText = '',
    selectedValue = '',
    npsFilter: any = '',
    processView: any = '',
    CustomSurveyParam = false,
    searchKey = 'all'
  ) {
    if (selectedValue == 'All' || selectedValue == '') {
      CustomSurveyParam = true;
    } else {
      CustomSurveyParam = false;
    }
    let filterQuery = filterQueryGenerator(
      {
        Value: selectedValue,
        Text: searchText,
        Formatter: '',
      },
      this.filtersService
    );
    let ContactFilter = this.contactStaticsService.getFilter();
    let filter = this.filtersService.getFilterObject();
    let resultingProcessView;
    if (processView) {
      resultingProcessView = processView;
    } else {
      resultingProcessView = this.getProcessView(filter.SurveyType || 'All');
    }
    const language = localStorage.getItem('language');
    let SuppryFilter: any = {};
    // this.cols = [
    //   {
    //     Id: 'Following',
    //     Key: 'Following',
    //     header: 'Following',
    //     toolTip: '',
    //     isShow: true,
    //     Template: true,
    //     Align: 'left',
    //     isSort: false,
    //     Type: 'string',
    //     Translate: false,
    //   },
    //   {
    //     Id: 'Status',
    //     Key: 'Status',
    //     header: 'Status',
    //     Template: true,
    //     freez: false,
    //     toolTip: '',
    //     isShow: true,
    //     Align: 'center',
    //     isSort: true,
    //     Type: 'string',
    //     Translate: false,
    //   },
    //   {
    //     Id: 'Action',
    //     Key: 'Action',
    //     header: 'Action',
    //     field: 'Action',
    //     freez: false,
    //     toolTip: '',
    //     Template: true,
    //     isShow: true,
    //     Align: 'center',
    //     isSort: false,
    //     Type: 'string',
    //     Translate: false,
    //   },
    //   {
    //     Id: 'FollowUp',
    //     Key: 'FollowUp',
    //     header: 'Follow Up Response',
    //     freez: false,
    //     toolTip: '',
    //     isShow: true,
    //     ShowText: false,
    //     Align: 'center',
    //     isSort: false,
    //     Type: 'string',
    //     Translate: false,
    //   },
    // ];
    filter.showMeta = true;
    filter.PageNo = PageNo;
    filter.PageSize = Pagesize;
    filter.Filters = filterQuery;
    filter.Fields = '';
    filter.CustomSurveyParam = CustomSurveyParam;

    SuppryFilter['ReportingModel'] = filter;
    if (ContactFilter && ContactFilter['ClickThroughFilter'] && ContactFilter.ClickThroughFilter['greater']) {
      ContactFilter['ClickThroughFilter'].NpsFilter = npsFilter;
      SuppryFilter['ClickThroughFilter'] = ContactFilter['ClickThroughFilter'];
    } else if (npsFilter !== null && npsFilter.trim() !== '') {
      const Npsfilter = { NpsFilter: npsFilter };
      SuppryFilter['ClickThroughFilter'] = Npsfilter;
    } else if (ContactFilter?.ClickThroughFilter?.NpsFilter) {
      SuppryFilter['ClickThroughFilter'] = ContactFilter['ClickThroughFilter'];
    } else {
      SuppryFilter['ClickThroughFilter'] = null;
    }
    this.getClickContactStatsThroughForSurveyList();
    // const url = `${this.reportingUrl}/GetSurveyReport/${PageNo}/${Pagesize}/${CustomSurveyParam}/${isDeleted}/${processView}${this.contactStatsClickThrough}?languageCode=${language}`;
    const url = `${this.reportingUrl}/GetSurveyResponse1/${isDeleted}/${resultingProcessView}/${searchKey}/${language}`;
    return this.httpClient
      .cache()
      .post(url, SuppryFilter)
      .pipe(
        tap((_: any) => log.debug('in tap getData')),
        // map((res: ISurveyListResponse) => {
        mergeMap(async (res) => this.transformSurveyListreport(res, isDeleted, SuppryFilter))
      );
  }

  transformSurveyListreport(res: any, isDeleted: boolean, SuppryFilter: {}) {
    var responseData = responseTransformation(res);
    if (isDeleted == true) {
      this.totalNoRecords = res.ref_data.DeletedTotalPages;
      return this.mapDeletedGridData(responseData);
    } else {
      // if (!res?.ref_data?.Questions) {
      //   return;
      // }
      this.totalNoRecords = res.ref_data.TotalPages;
      return this.mapGridData(
        responseData,
        res.ref_data.Questions,
        res.ref_data.AlertData,
        res.ref_data.TotalPages,
        res.ref_data.DeletedTotalPages,
        res.ref_data.AlertTotalPages
      );
    }
  }

  setFilter(filter: any) {
    this.filter = { ReportingModel: this.filtersService.getFilterObject(), ClickThroughFilter: filter };
  }
  getFilter() {
    return this.filter;
  }

  mapGridData(
    data: any,
    questions: any[],
    hotAlert: SurveListHotAlert[],
    totalPage: number,
    deletedTotalPage: number,
    alertTotalPage: number
  ) {
    this.surveys = [];
    data.Response.Data.data.forEach((item: any, index: any) => {
      if (item.kpis !== null && item.kpis !== undefined) {
        Object.assign(item, item.kpis);
      }
      if (item.kpi_qp !== null && item.kpi_qp !== undefined) {
        Object.assign(item, item.kpi_qp);
      }
      if (item.kpi_dg !== null && item.kpi_dg !== undefined) {
        Object.assign(item, item.kpi_dg);
      }
      if (item.questions_qp !== null && item.questions_qp !== undefined) {
        const regex = /(<([^>]+)>)/gi;
        for (const property in item.questions_qp) {
          item.questions_qp[property] = item.questions_qp[property].replace(regex, '');
        }
        Object.assign(item, item.questions_qp);
      }
      if (item.questions_dg !== null && item.questions_dg !== undefined) {
        const regex = /(<([^>]+)>)/gi;
        for (const property in item.questions_dg) {
          item.questions_dg[property] = item.questions_dg[property].replace(regex, '');
        }
        Object.assign(item, item.questions_dg);
      }
      if (item.questions_dg !== null && item.questions_dg !== undefined) {
        Object.assign(item, item.questions_dg);
      }
    });
    const response: any = {
      grid: data?.Response?.Data?.data,
      Header: data?.Response?.Data?.ref_data?.Data,
      toatlRecords: data?.Response?.Data?.ref_data?.TotalPages,
      deletedToatlRecords: data?.Response?.Data?.ref_data?.DeletedTotalPages,
      alertToatlRecords: data?.Response?.Data?.ref_data?.AlertTotalPages,
      alertTagQuestion: [],
    };
    response.grid.forEach((row: any) => {
      response.Header.forEach((header: any) => {
        if (row.hotalert) {
          if (row.hotalert[header.Key] == true) {
            const isAlertTage = response.alertTagQuestion.some((tagQuestion: any) => {
              return tagQuestion == header.Key;
            });
            if (!isAlertTage) {
              response.alertTagQuestion.push(header.Key);
            }
            header.Renderer[0].inject[0].props.className = 'info-strip-long red';
            header.Renderer[0].inject[0].text = ``;
          } else if (row.hotalert[header.Key] == false) {
            const isAlertTage = response.alertTagQuestion.some((tagQuestion: any) => {
              return tagQuestion == header.Key;
            });
            if (!isAlertTage) {
              response.alertTagQuestion.push(header.Key);
            }
            header.Renderer[0].inject[0].props.className = 'info-strip-long green';
            header.Renderer[0].inject[0].text = ``;
          } else if (row.hotalert[header.Key] == 'Non') {
            const isAlertTage = response.alertTagQuestion.some((tagQuestion: any) => {
              return tagQuestion == header.Key;
            });
            if (!isAlertTage) {
              response.alertTagQuestion.push(header.Key);
            }
            header.Renderer[0].inject[0].props.className = 'info-strip-long black';
            header.Renderer[0].inject[0].text = ``;
          } else {
          }
        }
      });
    });
    return response;
  }

  mapDeletedGridData(data: any) {
    this.surveys = [];
    data.Response.Data.data.forEach((item: any, index: any) => {
      if (item.kpis !== null && item.kpis !== undefined) {
        Object.assign(item, item.kpis);
      }
      if (item.kpi_qp !== null && item.kpi_qp !== undefined) {
        Object.assign(item, item.kpi_qp);
      }
      if (item.kpi_dg !== null && item.kpi_dg !== undefined) {
        Object.assign(item, item.kpi_dg);
      }
      if (item.questions_qp !== null && item.questions_qp !== undefined) {
        const regex = /(<([^>]+)>)/gi;
        for (const property in item.questions_qp) {
          item.questions_qp[property] = item.questions_qp[property].replace(regex, '');
        }
        Object.assign(item, item.questions_qp);
      }
      if (item.questions_dg !== null && item.questions_dg !== undefined) {
        const regex = /(<([^>]+)>)/gi;
        for (const property in item.questions_dg) {
          item.questions_dg[property] = item.questions_dg[property].replace(regex, '');
        }
        Object.assign(item, item.questions_dg);
      }
      if (item.questions_dg !== null && item.questions_dg !== undefined) {
        Object.assign(item, item.questions_dg);
      }
    });
    // var page = mapDynamicColumns(data);
    // if (page?.extraData) {
    //   page.extraData.map((x: any) => {
    //     this.cols.unshift(x);
    //   });

    //   page.extraData = this.cols;
    // }
    const response: any = {
      grid: data?.Response?.Data?.data,
      Header: data?.Response?.Data?.ref_data?.Data,
      toatlRecords: data?.Response?.Data?.ref_data?.TotalPages,
      deletedToatlRecords: data?.Response?.Data?.ref_data?.DeletedTotalPages,
      alertToatlRecords: data?.Response?.Data?.ref_data?.AlertTotalPages,
      alertTagQuestion: [],
    };
    return response;
  }
  getProcessView(surveyType: string | null | undefined) {
    let processView = '0'; // Default value if SurveyType doesn't match any cases

    switch (surveyType) {
      case 'All':
        processView = '0';
        break;
      case 'QP':
        processView = '1';
        break;
      case 'DG':
        processView = '2';
        break;
      default:
        processView = '0';
        break;
    }

    return processView;
  }
  changeValue(value: any) {
    try {
      return parseFloat(Math.round(value * 100) / 100 + '');
    } catch (e) {
      if (value) return value;
      else return 0.0;
    }
  }
}
