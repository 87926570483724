import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { SidebarService } from '@app/shared/services/sidebar.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { SideBarItems } from '@app/core/types/sidebarItems';
import { GlobalFilterRouter } from '@app/shared/module/global-filters/global-filter-router';

@Component({
  selector: 'appc-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  isSidebar = false;
  animateClass = true;
  items: SideBarItems[];
  type: string = '';

  closeSideBarSubscribtion: Subscription;
  sideBarTabsSubscribtion: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public gbfRouter: GlobalFilterRouter,
    public sidebarService: SidebarService
  ) {}

  onBack(event: any) {
    this.gbfRouter.onBack();
  }

  ngOnInit() {
    this.closeSideBarSubscribtion = this.sidebarService.closeSideBar.subscribe(() => {
      this.sidebarService.isSidebar = false;
      this.sidebarService.isOpen = false;
    });
    this.items = this.sidebarService.getItems();
    this.sideBarTabsSubscribtion = this.sidebarService.sideBarTabs.subscribe((items) => {
      this.items = items;
    });
    this.sidebarService.isOpen = false;
    this.sidebarService.isSidebar = false;

    this.sidebarService.activeType.subscribe((type) => {
      this.type = type;
      this.updateActiveState();
    });
  }

  ngOnDestroy() {
    if (this.closeSideBarSubscribtion) {
      this.closeSideBarSubscribtion.unsubscribe();
    }
    if (this.sideBarTabsSubscribtion) {
      this.sideBarTabsSubscribtion.unsubscribe();
    }
    // this.sidebarService.closeSideBar.unsubscribe();
    // this.sidebarService.sideBarTabs.unsubscribe();
  }

  toggleSidebar(): void {
    if (!this.sidebarService.isSideBarLocked()) {
      this.sidebarService.isSidebar = !this.sidebarService.isSidebar;
      this.sidebarService.isOpen = this.sidebarService.isSidebar;
    }
  }

  onRoute(item: any) {
    if (item.click && item.isAllowRouting === false) {
      item.click();
      return;
    }
    if (item.route) {
      //   this.sidebarService.reset();
      this.sidebarService.isSidebar = false;
      this.router.navigateByUrl(item.route);
      localStorage.setItem('gbf_url', item.route);
    } else if (item.children) {
      item.showChild = !item.showChild;
    }
  }

  checkActive(route: string) {
    return this.router.url.includes(route);
  }

  goBack(): void {
    this.location.back();
  }

  onContextMenu(_bool: boolean) {
    if (_bool) return false;
    else return true;
  }
  updateActiveState() {
    if (this.items) {
      this.items.forEach((item) => {
        if (item.name === this.type) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    }
  }
}
