<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div *ngIf="DrillDownEnabled && clickThrough && clickThroughReport" class="widget-node">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>

  <!-- obw STATUS HTML -->
  <ng-container widgetArea>
    <div class="widget__content">
      <div class="obw-table">
        <table class="table table--status" *ngIf="topView">
          <tbody>
            <tr *ngFor="let item of topView; let i = index">
              <td *ngFor="let ii of item;">
                <h2 class="{{ii.color}}">
                  {{ ii.count | number }} <small>{{calcPrePercent(ii.count,ii.KeyCount,1) | number : '1.1-1'}} %</small>
                </h2>
                <p>{{ii.Key | translate}}</p>
              </td>
              <!-- <td>
                <h2 class="negative">
                  547 <small>(9.1%)</small>
                </h2>
                <p>Close with negative Follow-up Survey </p>
              </td> -->
            </tr>

            <!-- <tr>
            <td>
              <h2>

                45%

              </h2>
              <p>Follow-up Response Rate</p>
            </td>
            <td>
              <h2>

                559 <small>(47.5%)</small>

              </h2>
              <p>Total Closed</p>
            </td>
          </tr> -->
          </tbody>
        </table>
      </div>
    <!--  <div *ngIf="isTotalIssuesWidget"></div>-->
      <div class="obw-bottom-area">
        <div class="widget__graph">
          <div class="totalalert-widget">
            <h2 *ngIf="isTotalIssuesWidget" [style.color]="color">
              {{this.data2.kpiPerc}}%
            </h2>
            <h3 pTooltip="{{this.targetScoreByConfig}} % " tooltipPosition="bottom" *ngIf="isTotalIssuesWidget"><small>vs.</small> {{this.targetScoreByConfig}} %</h3>
            <div class="total-alert-graph">
              <div id="totalAlertsChartContainer" #containerTotalAlert></div>
            </div>
            <h4 *ngIf="isTotalIssuesWidget" class="numberofsurveys">{{'Issue Count' | translate}}</h4>
            <h5 *ngIf="isTotalIssuesWidget" class="ta-negative" (click)="RedirectToAlertList()">{{this.data2.alertStatusCount}}</h5>
          </div>
        </div>
        <div class="widget__counts">
          <div class="counts__table">
            <table>
              <tbody>
                <tr *ngFor="let d of data index as i;">
                  <td *ngIf="d.isShowColored">
                    <div class="count__symbol series--one clr-{{ colors[i] }}-background"></div>
                  </td>
                  <td *ngIf="!d.isShowColored">
                    &nbsp;
                  </td>
                  <td>
                    <div class="count__count" *ngIf="d.Count !== undefined && clickThroughReport">
                      <ng-container *ngIf="d.Count > 0 && d.ancher; else zeroCount">
                        <a *ngIf="d.ancher" [routerLink]="'/' + reportingRoutePrefix + '/alerts/list'"
                           [queryParams]="{status: paramsAlertStatus(d.Key)}">{{ d.Count | number }}</a>
                        <span *ngIf="!d.ancher">{{ d.Count | number }}</span>
                      </ng-container>
                      <ng-template #zeroCount>
                        {{ d.Count | number }}
                      </ng-template>
                    </div>
                    </td>
                    &nbsp;
                    <td>
                      <div class="count__count" *ngIf="isTotalIssuesWidget; else totalAlertPerc">
                        {{ changeValue((d.Count / alertStatusCount * 100)) }} %
                      </div>
                      <ng-template #totalAlertPerc>
                        <div class="count__percentage">{{ d.Percent | number : '1.1-1' }} %</div>
                      </ng-template>
                    <td>
                      <div class="series__name">{{ GetHotalertStatusTranslation(d.Key) }}</div>
                    </td>
                    </tr>
                    </tbody>
                    </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-widget-dynamic>
