<appc-page-layout
  [pageTitle]="'Survey List' | translate"
  (TabSelect)="onTapSelect($event)"
  [showFilter]="showFilter"
  [showTabs]="showFilter"
  (FilterApply)="applyFilters()"
  (FilterChange)="onFilterChange($event)"
>
  <appc-widget-xl [styleClass]="'fullpage--widget no-padding'">
    <div class="widget__filters">
      <div class="filter-item filter-item--tabs">
        <p-tabMenu [model]="headerTabs" styleClass="tabs-secondary" [activeItem]="selectedHeaderTabs"> </p-tabMenu>
      </div>
      <div class="filter-item filter-item--percentage" *ngIf="showProcessViewDropDown">
        <div class="widget-header-filter-dropdown">
          <label>{{ 'Process View' | translate }}</label>
          <p-dropdown
            [autoZIndex]="false"
            [options]="dropdowProcessViewOption"
            [(ngModel)]="selectedProcessView"
            (onChange)="searchText = ''"
            [style]="{ width: '200px' }"
            placeholder="{{ 'select_option_dropdown.placeholder' | translate }}"
          >
          </p-dropdown>
        </div>
      </div>
      <div class="filter-item filter-item--percentage" *ngIf="showNpsScoreDropDown">
        <div class="widget-header-filter-dropdown">
          <label>{{ 'NPS Score' | translate }}</label>
          <p-dropdown
            [autoZIndex]="false"
            [options]="dropdowNpsOption"
            [(ngModel)]="selectedNPS"
            (onChange)="searchText = ''"
            [style]="{ width: '200px' }"
            placeholder="{{ 'select_option_dropdown.placeholder' | translate }}"
          >
          </p-dropdown>
        </div>
      </div>
      <div class="filter-item filter-item--percentage">
        <div class="widget-header-filter-dropdown">
          <label>{{ 'Search From' | translate }}</label>
          <p-dropdown
            [autoZIndex]="false"
            [options]="dropdownOption"
            [(ngModel)]="selectedValue"
            (onChange)="searchText = ''"
            [style]="{ width: '200px' }"
            placeholder="{{ 'select_option_dropdown.placeholder' | translate }}"
          >
          </p-dropdown>
        </div>
      </div>
      <!-- <div class="filter-item filter-item--togglevirtualscroll">
        <p-button
          label="{{'virtual_scroll_button.title' | translate}}"
          (onClick)="showScrollIcons=!showScrollIcons"
          styleClass="btn"
        ></p-button>
      </div> -->
      <div class="filter-item filter-item--search">
        <div class="filter-search">
          <i class="far fa-search"></i>
          <input
            type="text"
            maxlength="50"
            placeholder="{{ 'search_text.placeholder' | translate }}"
            (keyup.enter)="search()"
            [disabled]="selectedValue === 'All'"
            [(ngModel)]="searchText"
          />
        </div>
      </div>
      <div class="filter-item filter-item--togglevirtualscroll">
        <p-button label="{{ 'search_button.title' | translate }}" (onClick)="search()" styleClass="btn"></p-button>
      </div>
    </div>
    <cvp-table
      #dt
      [columns]="cols"
      [value]="surveys"
      [paginator]="true"
      [rows]="pageSize"
      [resetPageOnSort]="false"
      [cols]="cols"
      [showDomesticScroller]="showDomesticScroller"
      [first]="first"
      (onPage)="paginate($event)"
      stickyHeader
      [stickyTop]="70"
      stickyClass="stickyHeader"
      [autoLayout]="true"
      [totalRecords]="totalRecords"
      [lazy]="true"
    >
      <!-- Grid Header Start-->
      <ng-template pTemplate="header" let-columns>
        <tr id="">
          <ng-container *ngFor="let col of cols">
            <th
              *ngIf="col.Visibility !== false"
              class="text-center"
              [cvpSortableColumn]="col.Key == 'question' ? col.Id : col.Key"
              title="{{ col.Label | translate }}"
            >
              {{ col.Key | translate }}
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <!-- Grid Header End-->
      <ng-template
        pTemplate="body"
        let-rowData
        let-editing="editing"
        let-expanded="expanded"
        let-ri="rowIndex"
        let-columns="columns"
      >
        <tr>
          <ng-container *ngFor="let col of columns">
            <td
              *ngIf="col.Visibility !== false"
              [class.text-center]="col.alignClass == 'text-center'"
              class="UnFrozenBody"
              [class.icon-cell]="col.Key == 'Type' || col.Key == 'Status'"
              [class.icon--circular]="col.Key == 'Type'"
              [ngClass]="{ 'sticky-cell': col.freez }"
            >
              <p
                reflecxColumn
                (on)="onReflecxColumn($event)"
                [columnMeta]="col"
                [rowData]="rowData"
              ></p>
              <!-- <ng-container *ngIf="col.field === 'kpi'">
                <ng-container *ngFor="let kpis of rowData.Kpis">
                  <span *ngIf="kpis.Name === col.Key">
                    <div style="text-align: center">
                      {{ kpis.Value | translate }}
                    </div>
                  </span>
                </ng-container>
              </ng-container> -->
              <!-- <ng-container *ngIf="col.field === 'question' && col.Template">
                <ng-container *ngIf="col.hotAlert">
                  <span *ngIf="col.hotAlert && rowData[col.Id]">
                    <span
                      class="info-strip-long"
                      *ngIf="rowData[col.Id] && rowData[col.Id].Alert == true"
                      [class.red]="rowData[col.Id] && rowData[col.Id].Alert == true"
                      title="{{ rowData[col.Id] ? rowData[col.Id].Value : '' }}"
                      [innerHtml]="checkStringExistting(rowData[col.Id] ? rowData[col.Id].Value : '')"
                    ></span>
                    <span
                      class="info-strip-long"
                      *ngIf="rowData[col.Id] && rowData[col.Id].Alert == false"
                      [class.green]="rowData[col.Id] && rowData[col.Id].Alert == false"
                      title="{{ rowData[col.Id] ? rowData[col.Id].Value : '' }}"
                      [innerHtml]="checkStringExistting(rowData[col.Id] ? rowData[col.Id].Value : '')"
                    ></span>
                  </span>
                  <span *ngIf="!col.hotAlert && rowData[col.Id] && rowData[col.Id].Value">
                    <span
                      class="info-strip-long black"
                      title="{{ rowData[col.Id] ? rowData[col.Id].Value : '' }}"
                      [innerHtml]="checkStringExistting(rowData[col.Id] ? rowData[col.Id].Value : '')"
                    ></span>
                  </span>
                </ng-container>
                <span
                  *ngIf="!col.hotAlert && rowData[col.Id]"
                  class="info-strip-long black"
                  title="{{ rowData[col.Id] ? rowData[col.Id].Value : '' }}"
                  [innerHtml]="checkStringExistting(rowData[col.Id] ? rowData[col.Id].Value : '')"
                ></span>
                <span *ngIf="rowData[col.Key] && !rowData[col.Id]" class="info-strip-long black">
                  {{ rowData[col.Key] }}
                </span>
              </ng-container> -->
              <!-- <span *ngIf="col.Key == 'FollowUp'">
                <span class="info-strip completed" *ngIf="rowData[col.Key] == true">{{ 'Y' | translate }}</span>
                <span class="info-strip failed" *ngIf="rowData[col.Key] == false">{{ 'N' | translate }}</span>
              </span> -->
              <ng-container *ngIf="alertTagQuestion.includes(col.Key)">
                  <p style="text-align: center;"><span class="{{classAdd(rowData,col)}}" title="{{rowData[col.Key]}}">{{rowData[col.Key]}}</span></p>
              </ng-container>
              <span *ngIf="col.Key == 'following'">
                <ng-container *ngIf="rowData['status']">
                  <button
                    class="btn btn-info"
                    title="Click on Button to Follow up Emails"
                    (click)="FollowUpAlertSurvey(rowData.hotalert_id, rowData.response_id)"
                    *ngIf="rowData['isFollowButton']"
                    translate
                  >
                    {{ 'Follow.title' | translate }}
                  </button>
                  <span *ngIf="!rowData['isFollowButton']" class="info-strip">{{ 'Following' | translate }}</span>
                </ng-container>
              </span>
              <span *ngIf="col.Key == 'action'">
                <a
                  *ngIf="rowData['status'] != 'New'"
                  [routerLink]="'/' + reportingRoutePrefix + '/alerts/customer-history/' + rowData.master_customer_id"
                >
                  <i class="far fa-edit" pTooltip="{{ 'Action' | translate }}"></i>
                </a>
              </span>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft">
        <p *ngIf="pageNumber * pageSize <= dt.totalRecords">
          {{ 'Showing' | translate }} {{ pageSize * (pageNumber - 1) + 1 }} - {{ pageNumber * pageSize }} {{'of' | translate}}
          {{ dt.totalRecords }}
        </p>
        <p *ngIf="dt.totalRecords == 0">{{ 'Showing' | translate }} {{ 0 }} - {{ dt.totalRecords }}</p>
        <p *ngIf="pageNumber * pageSize > dt.totalRecords && dt.totalRecords != 0">
          {{ 'Showing' | translate }} {{ pageSize * (pageNumber - 1) + 1 }} - {{ dt.totalRecords }} {{'of' | translate}}
          {{ dt.totalRecords }}
        </p>
      </ng-template>
      <ng-template pTemplate="paginatorright">
        <span class="per-page">{{ label | translate }}</span>
        <p-dropdown
          [autoZIndex]="true"
          [options]="pageDropDown"
          (onChange)="onChange($event)"
          [(ngModel)]="pageSize"
        ></p-dropdown>
      </ng-template>
    </cvp-table>
  </appc-widget-xl>
</appc-page-layout>
