<app-no-mobile *ngIf="shouldDisplay(pageName)"></app-no-mobile>
<main *ngIf="!shouldDisplay(pageName) && canRender" class="page-{{ pageName }} main">
  <div class="page_inner" myMatchHeight="widget--chart">
    <div id="print-section">
      <appc-layout-xl style="margin: 0">
        <div class="page_header">
          <!-- <app-page-title class="page_header_item page-breadcrumbs"></app-page-title> -->
          <h1 class="page_header_item page__title">{{ pageTitle | translate }}</h1>
          <div (click)="mOverLay = true">
            <rf-breadcrumb
              *ngIf="showBreadCrumOrg && gbfService['org_breadcrums'].length && !gbfService.isMultiOrg"
              class="page_header_item header-actions"
              [model]="gbfService['org_breadcrums']"
              [styleClass]="'breadcrumbs'"
            ></rf-breadcrumb>
            <rf-breadcrumb
              *ngIf="showBreadCrumOrg && gbfService.isMultiOrg"
              class="page_header_item header-actions"
              (click)="mOverLay = true"
              [model]="getMultiOrgTitle()"
              [styleClass]="'breadcrumbs'"
            ></rf-breadcrumb>
          </div>
          <div #morgFilters class="tooltip-multiOrg" *ngIf="multiOrgCacheTitleToolTip && multiOrgCacheTitleToolTip.length > 0 && mOverLay">
            <ng-container *ngFor="let morg of multiOrgCacheTitleToolTip">
              <rf-breadcrumb
                class="page_header_item header-actions"
                [model]="morg"
                [style]="{ 'text-align': 'left' }"
                [styleClass]="'breadcrumbs'">
              </rf-breadcrumb>
            </ng-container>
          </div>
        </div>
      </appc-layout-xl>
      <app-gbf-filter  [allexceptolderDisabled]="allexceptolderDisabled" *ngIf="showFilter && FiltersPermission"></app-gbf-filter>

      <!-- <appc-layout-xl *ngIf="showFilter && FiltersPermission && false">
        <appc-filters 
        #filterBar
        [showApplyFilterButton]="showApplyFilterButton" 
        [showVersion]="showVersion" 
        [showDealer]="showDealer" 
        [showModel]="showModel" 
        [allowDateFilters]="allowDateFilters"
        [orgMaxLevel]="orgMaxLevel"
        (filterEmitter)="onFilterEmit($event)" 
        [showHirerchyViaProp]="showHirerchy" 
        [showLanguageChFilters]="showLanguageChFilters"
        [showlanguage]="showlanguage"
        [showCategoryFilters]="showCategoryFilters"
        [showSentimentFilters]="showSentimentFilters" 
        [showVocFilters]="showVocFilters"
		    [showSmVocFilters]="showSmVocFilters"
        [showSMFilters]="showSMFilters"
        [showSourceTypeFilter]="showSourceTypeFilter"
        [isSMCompetitorReport]="isSMCompetitorReport"
        [showVersionList]="showVersionList"
        [showCarModel]="showCarModel"
        [showCustomRange]="showCustomRange"
         [hideSomeFilters]="hideSomeFilters"
        [showProgramDate]="showProgramDate"
        (OnFilter)="onFilterApply($event)"></appc-filters>
      </appc-layout-xl> -->
      <!-- <appc-layout-xl *ngIf="showTabs && TabPermission">
        <p-tabMenu [model]="tabs" styleClass="'tabs'"
          [activeItem]="getActiveItem()">
        </p-tabMenu>
      </appc-layout-xl> -->
      <ng-content></ng-content>
    </div>
  </div>

  <!-- <div class="page_inner">
      <appc-layout-xl>
        <div class="page_header">
          <app-page-title
            [pageTitle]="pageTitle"
          >
          </app-page-title>
          <rf-breadcrumb
            *ngIf="showFilter"
            class="page_header_item filter-breadcrumbs main"
            [model]="items"
            [styleClass]="'breadcrumbs'"
          ></rf-breadcrumb>
        </div>
      </appc-layout-xl>
      <appc-layout-xl *ngIf="showFilter">
        <appc-filters (filterEmitter)="onFilterEmit($event)"></appc-filters>
      </appc-layout-xl>
      <appc-layout-xl *ngIf="showTabs">
        <p-tabMenu [model]="tabs" styleClass="'tabs'" [activeItem]="tabs[0]">
        </p-tabMenu>
      </appc-layout-xl>
      <ng-content></ng-content>
  </div> -->
</main>
