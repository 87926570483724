import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FiltersService } from '@app/shared/services/filters.service';
import { RestMessage } from '@app/core/types';
import { TreeNode } from 'primeng/api';
import { AuthenticationService } from '@app/core';
import { IContactStatsResponse } from './types/ContactStatsReponse';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ContactStatisticsService {
  contactStatisticsUrl = '/contactStatistics';
  user = this.authService.getUser();

  filter: any;
  constructor(
    private http: HttpClient,
    private filtersService: FiltersService,
    private gbfService: GlobalFilterService,
    private authService: AuthenticationService,
    private routeData: ActivatedRoute
  ) {}

  getContactStatistics(year: string = '2019') {
    const filters = this.gbfService.getFilterObject();
    return this.http.cache().post<IContactStatsResponse>(`${this.contactStatisticsUrl}/${year}`, filters);
  }
  export(data: TreeNode[]) {
    return this.http.post(`${this.contactStatisticsUrl}/export`, data);
  }

  setFilter(filter: any, drillDown: boolean) {
    this.filter = {
      ReportingModel: this.gbfService.getFilterObject(),
      ClickThroughFilter: filter,
      isDrillDown: drillDown,
    };
  }

  getFilter(isV2Call: boolean = false) {
    const npsFilter = this.routeData.snapshot.queryParams['nps'];
    if (npsFilter) {
      this.filter = undefined;
    }
    if (this.filter) {
      this.filter.ReportingModel = this.gbfService.getFilterObject();
      if (isV2Call && 'StartDate' in this.filter.ClickThroughFilter && 'EndDate' in this.filter.ClickThroughFilter) {
        this.filter.ReportingModel.StartDate = this.filter.ClickThroughFilter.StartDate;
        this.filter.ReportingModel.EndDate = this.filter.ClickThroughFilter.EndDate;
      }
    }
    return (
      this.filter || {
        ReportingModel: this.gbfService.getFilterObject(),
        ClickThroughFilter: null,
        isDrillDown: false,
      }
    );
  }
}
