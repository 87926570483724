import { environment } from '@env/environment';

export class Permission {
  // MENU
  public static readonly MENU_GLOBAL_DASHBOARD = 'reporting_globaldashboard';
  public static readonly MENU_MAIN_DASHBOARD = 'reporting_maindashboard';
  public static readonly MENU_HOTALERT = 'reporting_hotalerts';
  public static readonly MENU_REPORTS_SECTION = 'reporting_customreports';
  public static readonly MENU_TEXT_ANALYTICS = 'reporting_textanalytics';
  public static readonly MENU_REPORT_GENERATOR = 'reporting_reportgenerator';
  public static readonly MENU_CROSSTAB = 'reporting_crosstabs';
  public static readonly MENU_BULLETIN = 'traning_video_upload';
  public static readonly MENU_APPEALS = 'reporting_appeals';
  public static readonly MENU_Filter = 'reporting_showfilter';
  public static readonly MENU_ProgramTabs = 'reporting_showprogramtab';
  public static readonly MENU_DATASOURCE = 'datasource';
  public static readonly MENU_SURVEY = 'survey';
  public static readonly MENU_TRANSMISSION = 'transmission';
  public static readonly MENU_INSIGHT = 'insights';
  // public static readonly MENU_APPEALS = 'appeals';
  public static readonly MENU_LIBRARY = 'LibraryModule';
  public static readonly MENU_USER = 'user';
  public static readonly MENU_VOC = 'reporting_voc';
  public static readonly MENU_SOCIALMEDIA = 'reporting_socialmedia';
  public static readonly MENU_VOCDEMO = 'reporting_demovoc';

  // GLOBAL DASHBOARD
  public static readonly GLOBALDASHBOARD_HEAT_MAP_WIDGET = 'reporting_globaldahsboard_heatmap_widget';
  public static readonly GLOBALDASHBOARD_SALES_QUESTIONS_WIDGET = 'reporting_globaldashboard_salesquestionwidget';
  public static readonly GLOBALDASHBOARD_AREA_OF_IMPROVEMENT_WIDGET =
    'reporting_globaldahsboard_areaofimprovement_widget';
  public static readonly GLOBALDASHBOARD_KPI_WIDGET = 'reporting_globaldahsboard_kpi_widget';
  public static readonly GLOBALDASHBOARD_PERFORMANCE_TREND_WIDGET = 'reporting_globaldahsboard_performancetrend_widget';
  public static readonly GLOBALDASHBOARD_PERFORMANCE_TREND_QUESTION_WIDGET =
    'reporting_globaldahsboard_performancetrend_question_widget';

  public static readonly GLOBALDASHBOARD_RECOMMENDED_QUESTIONS_WIDGET =
    'reporting_globaldahsboard_recommendedquestions_widget';
  public static readonly GLOBALDASHBOARD_SURVEYCOMPLETION_WIDGET =
    'reporting_globaldahsboard_surveycompletionreport_widget';
  public static readonly GLOBALDASHBOARD_TOPBOTTOM_PERFORMANCE_WIDGET =
    'reporting_globaldahsboard_topbottomperformance_widget';
  public static readonly GLOBALDASHBOARD_VOC_SENTIMENT_WIDGET = 'reporting_globaldahsboard_vocsentimentcount_widget';
  public static readonly GLOBALDASHBOARD_YEAR_OVER_YEAR_WIDGET = 'reporting_globaldahsboard_yearoveryear_widget';
  public static readonly GLOBALDASHBOARD_CUSTOMERJOURNEY_WIDGET = 'reporting_globaldahsboard_customerJourney_Widget';
  public static readonly GLOBALDASHBOARD_CUSTOMERJOURNEY_WIDGET_v2 =
    'reporting_globaldahsboard_customerJourney_Widget_V2';
  public static readonly GLOBALDASHBOARD_CUSTOMERCOMMENTS_WIDGET = 'reporting_globaldahsboard_customercomments_widget';
  public static readonly GLOBALDASHBOARD_DEALER_PERFORMANCE_WIDGET =
    'reporting_globaldahsboard_dealer_performance_widget';

  public static readonly GLOBALDASHBOARD_RECOMMENDED_ACTION_AP_BUTTON =
    'reporting_globaldahsboard_recommendedactions_widget_ap_button';
  public static readonly GLOBALDASHBOARD_RECOMMENDED_ACTION_Widget =
    'reporting_globaldahsboard_recommendedactions_widget';
  public static readonly GLOBALDASHBOARD_TOP_5_CLOSED_LOOP_ALERTS = 'reporting_globaldahsboard_top_5_closed_alerts';
  public static readonly GLOBALDASHBOARD_KEY_PERFORMANCE = 'reporting_globaldahsboard_keyperformance_widget';
  public static readonly GLOBALDASHBOARD_KEY_PERFORMANCE_INDICATOR =
    'reporting_globaldahsboard_keyperformanceindicators_widget';
  public static readonly GLOBALDASHBOARD_CUSTOMERQUESTIONCOMMENT = 'reporting_globaldashboard_customerquestioncomment';
  public static readonly GLOBALDASHBOARD_NPS_SCORE_WIDGET = 'reporting_globaldahsboard_nps_widget';
  public static readonly GLOBALDASHBOARD_PROCESS_VIEW_DROPDOWN = 'reporting_globaldahsboard_process_view_dropdown';
  public static readonly GLOBALDASHBOARD_SURVEYLIST_WIDGET = 'reporting_surveylistwidget';
  public static readonly GLOBALDASHBOARD_SURVEY_TREND_WIDGET = 'reporting_globaldahsboard_surveytrend_widget';
  public static readonly GLOBALDASHBOARD_FIXED_RIGHT_FIRST_TIME_TREND =
    'reporting_frft_widget_fixed_right_first_time_trend';
  public static readonly GLOBALDASHBOARD_PERFORMANCE_TREND_WIDGET_INFINITI =
    'reporting_widget_performance_trend_widget';
  public static readonly GLOBALDASHBOARD_TOP_OPPORTUNITY_WIDGET = 'reporting_widget_top_opportunity_widget';
  public static readonly GLOBALDASHBOARD_FRFT_TREND_WIDGET = 'reporting_globaldahsboard_frfttrend_widget';
  public static readonly GLOBALDASHBOARD_PERF_TREND_WIDGET = 'reporting_globaldahsboard_perftrend_widget';
  public static readonly GLOBALDASHBOARD_SURVEYLIST = 'dashboard_customreports_surveylist';
  public static readonly GLOBALDASHBOARD_SURVEYLIST_DELETED = 'dashboard_customreports_surveylist_deleted';
  //HOT ALERT DASHBOARD
  public static readonly GLOBALDASHBOARD_HOTALERT_WIDGET = 'reporting_globaldahsboard_hotalerts_widget';
  public static readonly HOTALERT_TOTALALERTS_WIDGET = 'reporting_hotalert_totalalerts_widget';
  public static readonly HOTALERT_ALERTSEFFICIENCY_WIDGET = 'reporting_hotalert_alertsefficiency_widget';
  public static readonly HOTALERT_TOPBOTTOM_PERFORMANCE_WIDGET = 'reporting_hotalert_topbottomperformers_widget';
  public static readonly HOTALERT_REASONSESCALATIONS_WIDGET = 'reporting_hotalert_reasonsescalations_widget';
  public static readonly HOTALERT_TOPRESOLUTIONS_WIDGET = 'reporting_hotalert_top5resolutions_widget';
  public static readonly HOTALERT_RE_CONTACT_CUSTOMER_WIDGET = 'reporting_re_contact_customers_widget';

  // REPORTING
  public static readonly REPORT_CONSTACTSTATS = 'reporting_customreports_contactstatistics';
  public static readonly REPORT_ORGANIZATION_SUMMARY = 'reporting_customreports_organizationsummary';
  public static readonly REPORT_SURVEYLIST = 'reporting_customreports_surveylist';
  public static readonly REPORT_SURVEYLIST_DELETED = 'reporting_customreports_surveylist_deleted';
  public static readonly REPORT_PERFORMANCE_TREND = 'reporting_customreports_performancetrend';
  public static readonly REPORT_MANIPULATION_REPORT = 'reporting_customreports_manipulationreport';
  public static readonly REPORT_MANAGEMENT_REPORT = 'reporting_customreports_managementreport';
  public static readonly REPORT_SERVICE_ADVISOR_REPORT = 'reporting_customreports_serviceadvisor';
  public static readonly REPORT_PORTALTRAFFIC = 'reporting_customreports_portaltraffic';
  public static readonly REPORT_LOGIN_SUMMARY = 'reporting_customreports_portaltraffic_loginsummary';
  public static readonly REPORT_PORTALTRAFFIC_LOGINDETAIL = 'reporting_customreports_portaltraffic_logintrafficdetail';
  public static readonly REPORT_PORTALTRAFFIC_LOGINSUMMARY =
    'reporting_customreports_portaltraffic_logintrafficsummary';
  public static readonly REPORT_HOTALERT_LIST = 'reporting_hotalerts_hotalertlist';
  public static readonly REPORT_CUSTOM_REPORTS = 'reporting_custom_reports';
  public static readonly REPORT_COMPARISON = 'reporting_customreports_comparison';
  public static readonly REPORT_Sample_Disposition = 'reporting_sample_disposition';

  public static readonly REPORT_DAY_OF_WEEK_REPORT = 'reporting_day_of_week_report';
  public static readonly REPORT_DAILY_REPORT = 'reporting_daily_report';
  public static readonly REPORT_DAY_OF_WEEK_REPORT_SCORE_WIDGET = 'reporting_day_of_week_report_score';
  public static readonly REPORT_DAY_OF_WEEK_REPORT_RO_PERC_DISTRIBUTION =
    'reporting_day_of_week_report_ro_perc_distribution';
  public static readonly REPORT_DAY_OF_WEEK_REPORT_HACE_ACE_INDEX_WIDGET =
    'reporting_day_of_week_report_hace_ace_index';
  public static readonly REPORT_DAY_OF_WEEK_REPORT_MPI_PERC_YES_WIDGET = 'reporting_day_of_week_report_mpi_perc_yes';
  public static readonly REPORT_DAY_OF_WEEK_REPORT_MPI_YES_VS_NO_WIDGET = 'reporting_day_of_week_report_mpi_yes_vs_no';
  public static readonly REPORT_DAY_OF_WEEK_REPORT_IMPACT_OF_MPI_WIDGET = 'reporting_day_of_week_report_impact_of_mpi';
  public static readonly REPORT_DAY_OF_WEEK_REPORT_MPI_YES_WIDGET = 'reporting_day_of_week_report_mpi_yes';
  public static readonly REPORT_DAY_OF_WEEK_REPORT_MPI_NO_WIDGET = 'reporting_day_of_week_report_mpi_no';
  public static readonly REPORT_DAY_OF_WEEK_REPORT_OC_MAINT_YES_WIDGET = 'reporting_day_of_week_report_oc_maint_yes';
  public static readonly REPORT_DAY_OF_WEEK_REPORT_OC_MAINT_NO_WIDGET = 'reporting_day_of_week_report_oc_maint_no';
  public static readonly REPORT_EMAIL_DISPOSITION_REPORT = 'reporting_email_disposition_report';
  public static readonly REPORT_ALIAS_REPORT = 'reporting_alias_report';
  public static readonly REPORT_DEALER_VARIABLE_BONUS = 'reporting_dealer_variable_bonus';
  public static readonly TARGET_UPDATE_MODULE = 'target_update_module';

  //FTFT Reports
  public static readonly REPORT_FRFT_REPORT = 'reporting_frft_reports';
  public static readonly REPORT_FRFT_OVERVIEW = 'reporting_frft_widget_frft_overview';
  public static readonly REPORT_FTFT_ROOT_CAUSES = 'reporting_frft_widget_ftft_root_causes';
  public static readonly REPORT_BY_MODEL_Q6NO = 'reporting_frft_widget_frft_by_model_q6no';
  public static readonly REPORT_FRFT_BY_SERVICE_ADVISOR_TECHNICIAL_Q6NO =
    'reporting_frft_widget_frft_by_service_advisor_technicial_q6no';
  public static readonly REPORT_REPORTING_FRFT_WIDGET_FRFT_REASONS_BY_TECHNICIAN_3MR =
    'reporting_frft_widget_frft_reasons_by_technician_3MR';
  public static readonly REPORT_FRFT_WIDGET_FRFT_CUSTOMER_COMMENTS = 'reporting_frft_widget_frft_customer_comments';
  public static readonly REPORT_FRFT_NISSAN_UNIVERSITY_CERTIFICATION =
    'reporting_frft_widget_frft_nissan_university_certification';
  public static readonly REPORT_FRFT_ASR_UTILIZATION = 'reporting_frft_widget_frft_asr_utilization';

  // TEXT ANALYTIC / VOC
  //public static readonly REPORT_TEXTANALYTIC_VOCDASHBOARD = 'reporting_textanalytics_vocdashboard';
  public static readonly REPORT_TEXTANALYTIC_VOCDASHBOARD = 'reporting_textanalytics';
  public static readonly REPORT_TEXTANALYTIC_TREEREPORT = 'reporting_textanalytics_treereport';
  public static readonly REPORT_TEXTANALYTIC_COMMENTLIST = 'reporting_textanalytics_commentlist';
  public static readonly REPORT_TEXTANALYTIC_CATEGORYCONFIGURATION = 'reporting_textanalytics_categoryconfiguration';
  public static readonly REPORT_TEXTANALYTIC_VOCINTERFACE = 'reporting_textanalytics_vocinterface';

  public static readonly VOCDASHBOARD_SENTIMENT_BY_REGION_WIDGET = 'reporting_vocdashboard_sentimentbyregion';
  public static readonly VOCDASHBOARD_SENTIMENT_COUNT_WIDGET = 'reporting_vocdashboard_sentiment_count';
  public static readonly VOCDASHBOARD_CATEGORY_SCORE_CORRELATION_WIDGET =
    'reporting_vocdashboard_category_score_correlation';
  public static readonly VOCDASHBOARD_CATEGORIES_DISPLAYED_OVER_TIME_PERIOD_WIDGET =
    'reporting_vocdashboard_categories_displayed_over_time_period';
  public static readonly VOCDASHBOARD_CATEGORY_FREQUENCY_AND_SCORE_CORELATION_WIDGET =
    'reporting_vocdashboard_category_frequency_and_score_corelation';
  public static readonly VOCDASHBOARD_CATEGORY_SUMMARY_WIDGET = 'reporting_vocdashboard_category_summary';
  public static readonly VOCDASHBOARD_SENTIMENT_TREND_WIDGET = 'reporting_vocdashboard_sentiment_trend';
  public static readonly VOCDASHBOARD_TRENDING_TOPICS_WIDGET = 'reporting_vocdashboard_trending_topics';
  public static readonly VOC_SHOW_UNASSIGNED_COMMENTS = 'reporting_show_unassigned_comments';
  public static readonly SOCIALMEDIADASHBOARD_KPI_VOC = 'reporting_voc_kpi';

  public static readonly VOCDASHBOARD_KPI_CATEGORY_SUMMARY = 'reporting_vocdashboard_kpi_category_summary';
  public static readonly VOCDASHBOARD_KPI_TOP_PERFORMER = 'reporting_vocdashboard_kpi_top_performer';
  public static readonly VOCDASHBOARD_KPI_TRENDING_TOPICS_STATS = 'reporting_vocdashboard_kpi_topics_stats';
  public static readonly VOCDASHBOARD_KPI_SENTIMENT_SUMMARY = 'reporting_vocdashboard_kpi_sentiment_summary';

  // SOCIAL MEDIA
  public static readonly REPORT_SOCIALMEDIA_DASHBOARD = 'reporting_sm_dashboard';
  public static readonly REPORT_SOCIALMEDIA_RANKINGREPORT = 'reporting_sm_ranking_report';
  public static readonly REPORT_SOCIALMEDIA_REVIEWLIST = 'reporting_sm_review_list_report';
  public static readonly REPORT_SOCIALMEDIA_MASTERLIST = 'reporting_sm_master_list_report';
  public static readonly REPORT_SOCIALMEDIA_DEALERCOMPETITORS = 'reporting_sm_competitors_report';
  public static readonly REPORT_SOCIALMEDIA_TREEREPORT = 'reporting_socialmedia_treereport';
  public static readonly REPORT_SOCIALMEDIA_PERFORMANCETREND = 'reporting_sm_performancetrend';
  public static readonly REPORT_SOCIALMEDIA_DYNAMICTOPICSEARCH = 'reporting_sm_dynamictopicsearch';
  public static readonly SOCIALMEDIADASHBOARD_REVIEW_RESPONSE_TIME_WIDGET =
    'reporting_vocdashboard_review_response_time';
  public static readonly SOCIALMEDIADASHBOARD_REVIEW_RESPONSE_RATE_WIDGET =
    'reporting_vocdashboard_review_response_rate';
  public static readonly SOCIALMEDIADASHBOARD_TOTAL_REVIEWS_WIDGET = 'reporting_vocdashboard_total_reviews';
  public static readonly SOCIALMEDIADASHBOARD_RATINGS_OVER_TIME = 'reporting_sm_ratings_over_time';
  public static readonly SOCIALMEDIADASHBOARD_REVIEWS_OVER_TIME = 'reporting_sm_reviews_over_time';
  public static readonly SOCIALMEDIADASHBOARD_TOP_BOTTOM_PERFORMERS = 'reporting_sm_top_bottom_performers';
  public static readonly SOCIALMEDIADASHBOARD_RATINGS_DISTRIBUTION = 'reporting_sm_ratings_distribution';
  public static readonly SOCIALMEDIADASHBOARD_REVIEW_SITES_DISTRIBUTION = 'reporting_sm_review_sites_distribution';
  public static readonly SOCIALMEDIADASHBOARD_NOT_RESPONDED_REVIEWS = 'reporting_sm_not_responded_reviews';
  public static readonly SOCIALMEDIADASHBOARD_KPI_AVERAGE_RATINGS = 'reporting_sm_kpi_average_ratings';
  public static readonly SOCIALMEDIADASHBOARD_KPI_RESPONSE_RATE = 'reporting_sm_kpi_response_rate';
  public static readonly SOCIALMEDIADASHBOARD_KPI_RESPONSE_TIME = 'reporting_sm_kpi_response_time';
  public static readonly SOCIALMEDIADASHBOARD_KPI_TOTAL_REVIEWS = 'reporting_sm_kpi_total_reviews';
  public static readonly SOCIALMEDIADASHBOARD_DEALER_PROFILE = 'reporting_sm_dealer_profile';
  public static readonly SOCIALMEDIADASHBOARD_KPI_V2_AVERAGE_RESPONSE = 'reporting_sm_kpi_v2_average_response';
  public static readonly SOCIALMEDIADASHBOARD_KPI_V2_COMPETITION = 'reporting_sm_kpi_v2_competition';
  public static readonly SOCIALMEDIADASHBOARD_KPI_V2_REVIEW_SUMMARY = 'reporting_sm_kpi_v2_review_summary';
  public static readonly SOCIALMEDIADASHBOARD_KPI_V2_SOCIAL_PERFORMANCE_INDEX =
    'reporting_sm_kpi_v2_social_performance_index';
  public static readonly SOCIALMEDIADASHBOARD_KPI_V2_TOP_REVIEW_SOURCES = 'reporting_sm_kpi_v2_top_review_sources';
  public static readonly SOCIALMEDIADASHBOARD_KPI_V2 = 'reporting_sm_kpi_v2';
  public static readonly SOCIALMEDIADASHBOARD_REGIONAL_MAP = 'reporting_socialmedia_regionalmap';
  public static readonly SOCIALMEDIADASHBOARD_CATEGORY_DISPLAY_OVER_TIME =
    'reporting_socialmedia_categorydisplayedovertime';
  public static readonly SOCIALMEDIADASHBOARD_SENTIMENT_COUNT = 'reporting_socialmedia_sentimentcount';
  public static readonly SOCIALMEDIADASHBOARD_CATEGORY_SUMMARY = 'reporting_socialmedia_categorysummary';
  public static readonly SOCIALMEDIADASHBOARD_SENTIMENT_TREND = 'reporting_socialmedia_sentimenttrend';
  public static readonly SOCIALMEDIADASHBOARD_TRENDING_TOPICS = 'reporting_socialmedia_trendingtopics';
  public static readonly SOCIALMEDIA_SHOW_UNASSIGNED_COMMENTS = 'reporting_show_unassigned_comments_on_sm';

  public static readonly REPORTING_ALLOW_COMPETITOR_COLUMN_GENERIC = 'reporting_allow_column_';
  public static readonly SOCIALMEDIA_DASHBOARD_PERFORMANCETREND = 'reporting_sm_performance_trend';

  // VOC DEMO
  public static readonly REPORT_VOCDEMO_TREEREPORT = 'reporting_vocdemo_treereport';
  public static readonly REPORT_VOCDEMO_TEXTCLASSIFICATION = 'reporting_vocdemo_textclassification';
  public static readonly REPORT_VOCDEMO_AIKNOWLEDGE = 'reporting_vocdemo_aiknowledge';
  public static readonly REPORT_VOCDEMO_AUDIOSENTIMENT = 'reporting_vocdemo_audiosentiment';

  // Coach
  public static readonly REPORT_COACH_ACTIONPLAN = 'reporting_coach_actionplan';
  public static readonly REPORT_COACH_CONTACTREPORT = 'reporting_coach_contactreport';
  public static readonly REPORT_COACH = 'reporting_coach';

  // REPORT GENERATOR
  public static readonly REPORT_REPORTGENERATOR_MYREPORT = 'reporting_reportgenerator_myreport';
  public static readonly REPORT_REPORTGENERATOR_PUBLISHREPORT = 'reporting_reportgenerator_publishreport';
  public static readonly REPORT_REPORTGENERATOR_DAILYREPORTING = 'reporting_reportgenerator_dailyreporting';

  // FILTERS
  public static readonly FILTER_CONTACTSTATS_VIEWSELECTION = 'reporting_customreports_contactstatistics_viewselection';
  public static readonly FILTER_CONTACTSTATS_YEARSELECTION = 'reporting_customreports_contactstatistics_yearselection';
  public static readonly REPORTING_TRANSMISION_MONITORING = 'reporting_transmission_monitoring';
  public static readonly REPORTING_SHOW_DEALERTYPE_DROPDOWN = 'reporting_showdealerType_Dropdown';

  //INFINITY DASHBOARD
  public static readonly GLOBALDASHBOARD_DVB_WIDGET = 'reporting_globaldashboard_dvb_widget';
  public static readonly GLOBALDASHBOARD_FEATURE_SCORE_WIDGET = 'reporting_globaldahsboard_featured_score_widget';
  public static readonly GLOBALDASHBOARD_PORTAL_TRAFFIC_WIDGET = 'reporting_globaldashboard_portal_traffic_widget';
  public static readonly GLOBALDASHBOARD_Correct_Contact_Info_Outstanding =
    'reporting_globaldahsboard_correct_contactinfo_outstanding_widget';
  public static readonly GLOBALDASHBOARD_STAFF_PERFORMANCE_TREND =
    'reporting_globaldahsboard_staff_performancetrend_widget';
  public static readonly DASHBOARD_Activity_Monitoring_Icon_Visible = 'icon_Vissible_activity_monitoring';
}
