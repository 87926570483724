import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { SidebarService } from '@app/shared/services/sidebar.service';
import { Survey } from '../../../reporting/types/survey';
import { SelectItem, MenuItem } from 'primeng/api';
import { SurveyListService } from './survey-list.service';
import { Router, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { state, trigger, style, transition, animate } from '@angular/animations';
import { NavService } from '@reflecxshared/services/nav/nav.service';
import { BreadCrumbService } from '@app/shared/services/breadcrumb.service';
import { ExportService } from '@clearviewshared/services/export.service';
import { FiltersService } from '@app/shared/services/filters.service';
import { environment } from '@env/environment';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { CacheService } from '@app/shared/services/cache.service';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
import { ContactStatisticsService } from '../../../reporting/contact-statistics/contact-statistics.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import {
  DynamicFilters,
  FilterTypes,
  GlobalFilterRouter,
} from '@app/shared/module/global-filters/global-filter-router';
import { AuthenticationService } from '@app/core';
@Component({
  selector: 'reporting-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0,
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class SurveyListComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper', { static: true }) wrapperDiv: ElementRef;
  @ViewChild('dt', { static: true }) dataTable: any;
  @ViewChild('stickyRow', { static: true }) stickyRow: ElementRef;
  cols: any[] = [];
  surveys: Survey[];
  showScrollIcons = true;
  filter: any;
  filterWithoutLoader: any = '';
  searchText = '';
  field = 'All';
  selectedValue = 'All';
  searchEvent: String = '';
  state: RouterStateSnapshot;
  showDomesticScroller = true;
  pageSize = 25;
  pageNumber = 1;
  totalRecords = 0;
  selectedNPS = '';
  selectedProcessView = '';
  customersTotalRecords = 0;
  deletedToatlRecords = 0;
  alertToatlRecords = 0;

  showFilter = true;
  pageDropDown: SelectItem[];
  dropdownOption: SelectItem[];
  dropDownConfigData: any;
  label = 'Per Page';
  headerTabs: MenuItem[];
  selectedHeaderTabs: MenuItem;
  clonedSurveys: { [s: string]: Survey } = {};

  currentProgram = '';
  currentType = 'All Customers';
  isDeleted = false;
  reportingRoutePrefix: any = environment.reportingRoutePrefix;
  subscriptionExport: Subscription;
  subscriptionExportAll: Subscription;

  first = 1;
  sortEvent: any;
  dropdowNpsOption: SelectItem[];
  dropdowProcessViewOption: SelectItem[];
  paraMonth: number;
  paraGreater: number;
  paraLess: number;
  timeZoneCode: string;
  showNpsScoreDropDown: boolean;
  showProcessViewDropDown: boolean;
  permissions: NgxPermissionsObject;
  subscriptionCache: Subscription[] = [];
  eventName = '';
  searchKey = '';
  alertTagQuestion: Array<string> = [];
  constructor(
    private router: Router,
    private filterService: FiltersService,
    private navService: NavService,
    private sideBarService: SidebarService,
    private service: SurveyListService,
    private routeData: ActivatedRoute,
    private breadcrumbService: BreadCrumbService,
    private exportService: ExportService,
    private notificationsService: NotificationsService,
    private contactStaticsService: ContactStatisticsService,
    public translateService: TranslateService,
    private translationHelperService: TranslationHelperService,
    public appConfig: AppConfigurationService,
    public cd: ChangeDetectorRef,
    private cacheService: CacheService,
    private gbfService: GlobalFilterService,
    public gbfRouter: GlobalFilterRouter,
    public ngxPermission: NgxPermissionsService,
    private paramRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private gfService: GlobalFilterService
  ) {
    this.state = router.routerState.snapshot;
    this.subscriptionCache.push(
      this.gbfService.gbfDateChange.subscribe((data: any) => {
        if (this.contactStaticsService?.filter?.ClickThroughFilter) {
          const dates = data.dates.split('|');
          this.contactStaticsService.filter.ClickThroughFilter.greater = moment(dates[0]).get('M') + 1;
          this.contactStaticsService.filter.ClickThroughFilter.less = moment(dates[1]).get('M') + 1;
        }
      })
    );
    this.subscriptionCache.push(
      this.cacheService.for('/api/v1/surveylist').subscribe((res: any) => {
        if (res) {
          const surveyFilters = this.service.surveyFilter(this.filter, this.searchText);
          let SuppryFilter: any = {};
          SuppryFilter['ReportingModel'] = surveyFilters.filter;
          const trasnformRes = this.service.transformSurveyListreport(res, this.isDeleted, SuppryFilter);
          this.updateView(trasnformRes);
        }
      })
    );

    var channel = 'All';
    this.paramRoute.queryParams.subscribe((params) => {
      channel = params['channel'];
    });

    // #region Export
    this.subscriptionExport = this.exportService.getMessage().subscribe((message: any) => {
      this.searchKey = this.updateSearchKey(this.searchText, this.selectedValue, this.dropdownOption);
      this.service.exportCall(
        this.pageNumber,
        this.pageSize,
        true, //isPaginated
        this.isDeleted,
        this.searchText,
        this.selectedValue,
        this.selectedNPS,
        this.selectedProcessView,
        this.searchKey
      );
    });
    // #endregion

    // #region Export All
    this.subscriptionExportAll = this.exportService.getMessageForExportAll().subscribe((message: any) => {
      this.searchKey = this.updateSearchKey(this.searchText, this.selectedValue, this.dropdownOption);
      this.service.exportCall(
        this.pageNumber, //pageNumber
        this.pageSize,
        false, //isPaginated
        this.isDeleted,
        this.searchText,
        this.selectedValue,
        this.selectedNPS,
        this.selectedProcessView,
        this.searchKey
      );
    });
    // #endregion
  }
  ngOnInit() {
    if (this.routeData.snapshot.queryParams['en']) {
      this.eventName = this.routeData.snapshot.queryParams['en'];
      this.gfService.setTab(this.eventName);
    }

    const npsFilter = this.routeData.snapshot.queryParams['nps'];
    this.selectedNPS = npsFilter != null ? npsFilter : '';

    // Commented by moiz after discussion with zubair. It seems useless, could only impact echopark NPS widget. Unit test done working fine after commenting
    // if (npsFilter) {
    //   let filter = this.contactStaticsService?.filter?.ClickThroughFilter || {};
    //   filter.NpsFilter = npsFilter;
    //   this.contactStaticsService.setFilter(filter, false);
    // } else {
    //   this.contactStaticsService.setFilter('', false);
    // }
    this.permissions = this.ngxPermission.getPermissions();
    this.showNpsScoreDropDown = this.permissions[Permission.GLOBALDASHBOARD_NPS_SCORE_WIDGET] ? true : false;
    this.showProcessViewDropDown = this.permissions[Permission.GLOBALDASHBOARD_PROCESS_VIEW_DROPDOWN] ? true : false;
    this.menuReport();

    if (this.routeData.snapshot.params.status === 'deleted') {
      this.sideBarService.setActiveItems(this.translateService.instant('Survey List – Deleted Surveys'));
      this.navService.setTitleClearView(this.translateService.instant('Survey List – Deleted Surveys'));
      this.onCommandDeleted();
      // }
    } else {
      this.loadData();
      this.sideBarService.setActiveItems(this.translateService.instant('Survey List'));
      this.navService.setTitleClearView(this.translateService.instant('Survey List'));
    }

    this.breadcrumbService.updateBreadCrumb(this.translateService.instant('Reporting'));
    this.breadcrumbService.updateBreadCrumb(this.translateService.instant('Survey List'));
    this.pageDropDown = [
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: ' 100', value: 100 },
    ];

    this.searchEvent = 'no';
    this.SetHeaderTabs();
    this.selectedHeaderTabs = this.headerTabs[0];

    this.dropdownOption = [{ label: this.translateService.instant('select_option.title'), value: `All`, title: 'all' }];
    this.showFilter = this.service.filterShow;
    this.timeZoneCode = this.filterService.timeZoneCode;
    const data = this.authService.getUser();
    const config: [] = data.Configurations;
    const reportConfig = config.filter((e) => e['Identifier'] === 'survey_response_report_common')[0];
    this.dropDownConfigData = reportConfig['Fields']['filters'];
    for (let j = 0; j < this.dropDownConfigData.length; j++) {
      this.dropdownOption.push({
        label: this.translateService.instant(this.dropDownConfigData[j].label),
        value: this.dropDownConfigData[j].value,
        title: this.dropDownConfigData[j].key,
      });
    }
    const npsSetting: any = this.appConfig.getByIdentifier('dashboard.widget.npsScore');
    this.dropdowNpsOption = [{ label: this.translateService.instant('All'), value: '' }];
    if (Array.isArray(npsSetting.Fields?.config_info) && npsSetting.Fields?.config_info[0].configuration) {
      const npsConfig = JSON.parse(
        npsSetting?.ClientConfig == null && npsSetting?.ClientConfig?.Fields?.config_info.length > 0
          ? npsSetting.ClientConfig.Fields.config_info[0].configuration.replace(/'/g, '"')
          : npsSetting.Fields?.config_info[0].configuration.replace(/'/g, '"')
      );
      for (const idx of npsConfig) {
        this.dropdowNpsOption.push({ label: this.translateService.instant(idx.title), value: idx.key });
      }
    }
    this.fillKpiDropDown();
  }

  initParam() {
    const npsFilter = this.routeData.snapshot.queryParams['nps'];
    this.selectedNPS = npsFilter != null ? npsFilter : '';
  }

  fillKpiDropDown() {
    const processViewSetting: any = this.appConfig.getByIdentifier('survey_response.process_view');
    this.dropdowProcessViewOption = [];
    if (processViewSetting && processViewSetting['Fields']) {
      var obj = this.filterService.getFilterObject();
      if (obj) {
        let kpisConf: [] = processViewSetting.Fields;
        var kpiList = kpisConf.filter((f) => f['survey_id'] == obj.SurveyVersion)[0];
        if (kpiList) {
          let list: [] = kpiList['kpis'];
          for (var ii of list) {
            this.dropdowProcessViewOption.push({ label: this.translateService.instant(ii), value: ii });
          }
        }
      }
    }
    if (this.dropdowProcessViewOption.length > 0) {
      this.selectedProcessView = this.dropdowProcessViewOption[0].value;
    }
  }

  loadData() {
    this.gbfService.activatedUrl = this.state.url.toString();
    if (this.selectedValue !== 'All') {
      this.filter = "{'" + this.selectedValue + "' : /" + this.searchText + '/i}';
      this.field = this.selectedValue;
    } else {
      this.field = 'All';
      this.filter = '';
    }

    this.service
      .getSurveyListReport(this.pageNumber, this.pageSize, false, '', '', this.selectedNPS)
      .subscribe((res: any) => {
        this.updateView(res);
        this.cd.detectChanges();
      });
  }

  SetHeaderTabs() {
    //  let event = this.filterService.getFilterObject();
    const isShow = true;
    // switch (event.Event) {
    //   case "Service":
    //     this.currentType = "All Customers";
    //     isShow = false;
    //     break;
    //   case "Collision":
    //     this.currentType = "All Customers";
    //     isShow = false;
    //     break;
    //   default:
    //     isShow = true;
    //     break;

    // }

    this.headerTabs = [
      {
        label: this.translateService.instant('All Customers') + ' (' + this.customersTotalRecords + ')',
        visible: true,
        title: '',
        command: () => this.onCommandAll(),
      },
      {
        label: this.translateService.instant('Responses.customer_alerts') + ' (' + this.alertToatlRecords + ')',
        visible: this.permissions[Permission.REPORT_HOTALERT_LIST] ? true : false,
        title: '',
        command: () => this.onCommandAlerts(),
      },
      // {
      //   label: 'Active Customers',
      //   visible: false,
      //  command: () => this.onCommandAlerts(),
      // },

      {
        label: this.translateService.instant('Responses.deleted_surveys') + ' (' + this.deletedToatlRecords + ')',
        visible:
          this.permissions[Permission.REPORT_SURVEYLIST_DELETED] ||
          this.permissions[Permission.GLOBALDASHBOARD_SURVEYLIST_DELETED]
            ? true
            : false,
        title: '',
        command: () => this.onCommandDeleted(),
      },
    ];
  }

  async menuReport() {
    await this.exportService.sendMessageFromGlobalComponent('menu-report');
  }

  updateView(response: any) {
    this.cols = [];
    this.surveys = [];
    if (response) {
      this.alertTagQuestion = response.alertTagQuestion;
      this.cols = response.Header;

      this.cols = this.appConfig.getHideColumns(this.cols, 'Id', 'isShow', this.currentType);
      this.cols = this.cols.sort((a: any, b: any) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
      this.surveys = response.grid;
      const userData = this.authService.getUser();
      this.surveys.forEach((survey: any) => {
        if (!survey.watchers) {
          survey['isFollowButton'] = true;
        } else if (survey.watchers.length == 0) {
          survey['isFollowButton'] = true;
        } else if (survey.watchers.length == 0) {
          const isCurrentWatcher: any = survey.watchers.some((watcher: any) => {
            return watcher.email == userData.Email;
          });
          survey['isFollowButton'] = isCurrentWatcher == true ? false : true;
        }
      });
      this.customersTotalRecords = response.toatlRecords;
      this.totalRecords = response.toatlRecords;
      this.deletedToatlRecords = response.deletedToatlRecords;
      this.alertToatlRecords = response.alertToatlRecords;
      if (this.pageNumber === 1) {
        this.dataTable.first = 0;
      }

      if (this.isDeleted) {
        this.totalRecords = response.deletedToatlRecords;
      } else {
        this.totalRecords = response.toatlRecords;
      }

      this.SetHeaderTabs();
      let filter = this.headerTabs.filter(
        (x) =>
          x.label === this.translateService.instant(this.currentType) + ' (' + this.totalRecords + ')' &&
          x.visible === true
      );
      if (filter && filter.length) {
        this.selectedHeaderTabs = filter[0];
      } else {
        this.selectedHeaderTabs = this.selectedHeaderTabs;
      }
    }
  }

  route() {
    // this.router.navigateByUrl('');
  }

  onRowEditInit(survey: Survey) {
    this.clonedSurveys[survey.No] = { ...survey };
  }

  onChange(event: any) {
    this.pageSize = event.value;
    this.pageNumber = 1;
    this.service
      .getSurveyListReport(
        this.pageNumber,
        this.pageSize,
        this.isDeleted,
        this.searchText,
        this.selectedValue,
        this.selectedNPS,
        this.selectedProcessView
      )
      .subscribe((res: any) => {
        this.updateView(res);
      });
  }
  paginate(event: any) {
    this.first = event.first;
    this.pageNumber = event.first / event.rows + 1;
    this.service
      .getSurveyListReport(
        this.pageNumber,
        this.pageSize,
        this.isDeleted,
        this.searchText,
        this.selectedValue,
        this.selectedNPS,
        this.selectedProcessView
      )
      .subscribe((res: any) => {
        this.updateView(res);
      });
  }
  onReflecxColumn(e: any) {
    e.e.preventDefault();
    console.log(e);
  }
  onScroll(event: any) {
    //   this.wrapperDiv.nativeElement.scrollLeft = this.wrapperDiv.nativeElement.scrollLeft - 2;
    document.querySelectorAll('table thead tr.sticky-row th').forEach((x: any) => {
      x.setAttribute('style', `top:${this.wrapperDiv.nativeElement.scrollTop}px`);
    });
    document.querySelectorAll('table tbody tr.sticky-row td').forEach((x: any) => {
      x.setAttribute('style', `top:${this.wrapperDiv.nativeElement.scrollTop}px`);
    });
    document.querySelectorAll('table th.sticky-cell').forEach((x: any) => {
      x.setAttribute('style', `left:${this.wrapperDiv.nativeElement.scrollLeft}px`);
    });
    document.querySelectorAll('table td.sticky-cell').forEach((x: any) => {
      x.setAttribute('style', `left:${this.wrapperDiv.nativeElement.scrollLeft}px`);
    });
  }

  onRowEditSave(car: any) {
    // if (car.year > 0) {
    //     delete this.clonedCars[car.vin];
    //     this.messageService.add({severity:'success', summary: 'Success', detail:'Car is updated'});
    // }
    // else {
    //     this.messageService.add({severity:'error', summary: 'Error', detail:'Year is required'});
    // }
  }

  onTapSelect(program: string) {
    //this.initParam();
    this.initParam();
    // let event = this.filterService.getFilterObject();
    this.pageNumber = 1;
    this.first = 1;
    this.currentProgram = program;
    this.fillKpiDropDown();
    this.service
      .getSurveyListReport(
        this.pageNumber,
        this.pageSize,
        this.isDeleted,
        this.searchText,
        this.selectedValue,
        this.selectedNPS,
        this.selectedProcessView
      )
      .subscribe((res: any) => {
        this.updateView(res);
      });

    this.SetHeaderTabs();
  }
  updateSearchKey(searchText: string | null | undefined, selectedValue: string, dropdownOption: any[]) {
    // Rest of the function remains the same
    if (!searchText) {
      selectedValue = 'All';
    }

    if (dropdownOption && Array.isArray(dropdownOption) && dropdownOption.length > 0) {
      const matchingItem = dropdownOption.find((x) => x.value === selectedValue && x.title);
      if (matchingItem) {
        return matchingItem.title;
      }
    }

    return null; // Or any default value you want to set
  }
  search() {
    if (!this.searchText) {
      this.selectedValue = 'All';
    }
    this.searchKey = this.updateSearchKey(this.searchText, this.selectedValue, this.dropdownOption);

    this.pageNumber = 1;
    this.first = 1;
    this.searchEvent = 'yes';
    this.selectedProcessView;
    this.service
      .getSurveyListReport(
        this.pageNumber,
        this.pageSize,
        this.isDeleted,
        this.searchText,
        this.selectedValue,
        this.selectedNPS,
        this.selectedProcessView,
        false,
        this.searchKey
      )
      .subscribe((res: any) => {
        this.updateView(res);
      });
  }

  onCommandAll() {
    this.sideBarService.setActiveItems('Survey List');
    this.navService.setTitleClearView('Survey List');
    this.isDeleted = false;
    this.pageNumber = 1;
    this.first = 1;
    this.currentType = 'All Customers';
    this.service
      .getSurveyListReport(
        this.pageNumber,
        this.pageSize,
        this.isDeleted,
        this.searchText,
        this.selectedValue,
        this.selectedNPS,
        this.selectedProcessView
      )
      .subscribe((res: any) => {
        this.updateView(res);
      });
  }
  onCommandAlerts() {
    const viewableParams: Array<DynamicFilters> = [];
    viewableParams.push(
      {
        key: 'calendar',
        name: 'Calendar',
        value: this.gbfService.getCalenderRange(),
        type: FilterTypes.Global,
      },
      {
        key: 'search',
        name: 'Search',
        value: this.searchText,
        type: FilterTypes.Dynamic,
      },
      {
        key: 'search-field',
        name: 'Search Field',
        value: this.selectedValue,
        type: FilterTypes.Dynamic,
        displayValue: this.dropDownConfigData.find((x: any) => x.value === this.selectedValue)?.label,
      }
    );
    // this.gbfRouter.cloneAndAddHistory('/insights/alerts/list', 2);
    this.gbfRouter.reset = false;
    if (this.searchText && this.searchEvent === 'yes') {
      var query: any = {};
      query[this.selectedValue] = this.searchText;
      // console.log(query);
      this.gbfRouter.navigate(
        ['/insights/alerts/list'],
        { queryParams: { query } },
        { key: '/insights/alerts/list', filters: viewableParams },
        null,
        '2'
      );
      // if (this.selectedValue === 'customer_code') {
      //   this.router.navigate(['/insights/alerts/list'], { queryParams: { survey_id: this.searchText } });
      // } else if (this.selectedValue === 'customer_name') {
      //   this.router.navigate(['/insights/alerts/list'], { queryParams: { customer_name: this.searchText } });
      // } else if (this.selectedValue === 'vin') {
      //   this.router.navigate(['/insights/alerts/list'], { queryParams: { vin: this.searchText } });
      // }
    } else {
      this.gbfRouter.navigate(
        ['/insights/alerts/list'],
        {},
        { key: '/insights/alerts/list', filters: viewableParams },
        null,
        '2'
      );
    }
    // this.gbfRouter.reset = true;
  }
  getTranslatedAppealData(key: string, flag: string) {
    if (key) {
      if (flag === 'reason') {
        return this.translationHelperService.GetAppealRemovalReason(key);
      } else {
        return this.translationHelperService.GetAppealStatus(key);
      }
    } else {
      return key;
    }
  }

  GetHotalertStatusTranslation(key: string) {
    if (key != null && key.length > 0) {
      return this.translationHelperService.GetHotalertStatusTranslation(key);
    }
  }

  // GetSurveyKpiTranslation(key: string) {
  //   if (key != null && key.length > 0) {
  //     var val = key[0] ? key[0] : key[1];
  //     return this.translationHelperService.GetSurveyKpiNameTranslation(val);
  //   }
  // }

  GetSurveyKpiTranslation(key: string) {
    if (key != null && key.length > 0) {
      var text = '';
      var cnt = 0;
      for (var ii of key) {
        cnt++;
        if (key.length != cnt) {
          text += this.translationHelperService.GetSurveyKpiNameTranslation(ii) + ', ';
        } else {
          text += this.translationHelperService.GetSurveyKpiNameTranslation(ii);
        }
      }
      return text;
    }
  }

  onCommandDeleted() {
    if (!this.searchText) {
      this.selectedValue = 'All';
    }
    this.searchKey = this.updateSearchKey(this.searchText, this.selectedValue, this.dropdownOption);
    this.sideBarService.setActiveItems(this.translateService.instant('Survey List – Deleted Surveys'));
    this.navService.setTitleClearView(this.translateService.instant('Survey List – Deleted Surveys'));
    this.isDeleted = true;
    this.pageNumber = 1;
    this.currentType = 'Deleted Surveys';
    this.notificationsService.ActivityRoutingNotification('Visit', 'Survey List – Deleted Surveys', 'Reporting');
    this.service
      .getSurveyListReport(
        this.pageNumber,
        this.pageSize,
        this.isDeleted,
        this.searchText,
        this.selectedValue,
        this.selectedNPS,
        this.selectedProcessView,
        false,
        this.searchKey
      )
      .subscribe((res: any) => {
        // res.Header = res.Header.filter((thing: any) => {
        //   return thing.field !== 'RemovalType' && thing.field !== 'RemovalReason' && thing.field !== 'AppealComment';
        // });
        this.updateView(res);
      });
  }

  applyFilters() {
    this.pageNumber = 1;
    this.first = 1;

    this.service
      .getSurveyListReport(
        this.pageNumber,
        this.pageSize,
        this.isDeleted,
        this.searchText,
        this.selectedValue,
        this.selectedNPS,
        this.selectedProcessView
      )
      .subscribe((res: any) => {
        this.updateView(res);
      });
  }
  onFilterChange(event: MenuItem[]) {
    this.service.setFilter(event);
  }
  FollowUpAlertSurvey(HotAlertId: string, ResponseId: string) {
    this.service.updateFollowUpSurvey(HotAlertId, ResponseId).subscribe((res: any) => {
      const obj: any = this.surveys.filter((w: any) => w.response_id === ResponseId);
      if (obj && obj.length) {
        obj[0].isFollowButton = !obj[0].isFollowButton;
      }
    });
  }
  changeValue(value: any) {
    return parseFloat((value * 100) / 100 + '').toFixed(1);
  }
  checkStringExistting(obj: any) {
    if (obj == null || obj == undefined) {
      return obj;
    }
    var type = typeof obj;
    if (type == 'string') {
      var length = obj.length;
      if (length < 30) {
        return obj;
      } else {
        var str = obj.substring(0, 40);
        str += '..';
        return str;
      }
    } else {
      return obj;
    }
  }
  convertToLocalDate(date: string) {
    return new Date(date);
  }
  sortData(e: any) {
    if (e) {
      this.sortEvent = e;
    }
    let data = this.surveys;
    if (e.order === 1) {
      data.sort((leftSide: any, rightSide: any): number => {
        if (e.field.length < 30) {
          if (leftSide[e.field] < rightSide[e.field]) {
            return -1;
          }
          if (leftSide[e.field] > rightSide[e.field]) {
            return 1;
          }
        } else {
          const leftHandSide = leftSide[e.field] && leftSide[e.field].SortValue ? leftSide[e.field].SortValue : '';
          const rightHandSide = rightSide[e.field] && rightSide[e.field].SortValue ? rightSide[e.field].SortValue : '';

          if (leftHandSide < rightHandSide) {
            return -1;
          }
          if (leftHandSide > rightHandSide) {
            return 1;
          }
        }
      });
    } else {
      data.sort((leftSide: any, rightSide: any): number => {
        if (e.field.length < 30) {
          if (leftSide[e.field] > rightSide[e.field]) {
            return -1;
          }
          if (leftSide[e.field] < rightSide[e.field]) {
            return 1;
          }
        } else {
          const leftHandSide = leftSide[e.field] && leftSide[e.field].SortValue ? leftSide[e.field].SortValue : '';
          const rightHandSide = rightSide[e.field] && rightSide[e.field].SortValue ? rightSide[e.field].SortValue : '';

          if (leftHandSide > rightHandSide) {
            return -1;
          }
          if (leftHandSide < rightHandSide) {
            return 1;
          }
        }
      });
    }
    this.surveys = data;
  }

  ngOnDestroy() {
    this.subscriptionCache.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });
    this.subscriptionExport.unsubscribe();
    this.subscriptionExportAll.unsubscribe();
  }

  classAdd(row: any, col: any) {
    if (row.hotalert[col.Key] == true) {
      return 'info-strip-long red strap--with-ellipsis';
    } else if (row.hotalert[col.Key] == false) {
      return 'info-strip-long green strap--with-ellipsis';
    } else if (row.hotalert[col.Key] == 'Non') {
      return 'info-strip-long black strap--with-ellipsis';
    } else {
    }
  }
}
