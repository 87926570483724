import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FiltersService } from '@app/shared/services/filters.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { RestMessage } from '@app/core/types';
import { WidgetMapperService } from './widget.mapper.service';
import { Logger, AuthenticationService } from '@app/core';
import { map, filter } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';

import { ITotalAlertResultResponse } from './total-alerts/types/ITotalAlertResultResponse';
import { IKpisResponse } from './kpi-meter/types/IKpisResponse';
import { IRecommendedActionsResponse } from './recommended-actions/types/IRecommendedActionsResponse';
import { ITopBottomPerformerResponse } from './top-bottom-performers/types/ITopBottomPerformerResponse';
import { ITop5ResolutionsResponse } from './top-resolutions/types/ITop5ResolutionsResponse';
import { ICustomerCommentsResponse } from './customer-comments/types/ICustomerCommentsResponse';
import {
  IPerformanceTrendQuestionResponse,
  IPerformanceTrendResponse,
} from './performance-trend/types/IPerformanceTrendResponse';
import { ICustomerJourneyResponse } from './customer-journey-widget/types/ICustomerJourneyResponse';
import { IKeyPerformanceIndicators } from './key-performance-indicators/types/IKeyPerformanceIndicators';
import { IAlertsEfficiencyResponse } from './alerts-efficiency/types/IAlertsEfficiencyResponse';
import { IReasonsEscalationsResponse } from './reasons-escalations/types/IReasonsEscalationsResponse';
import { ICategorySummaryResponse } from './category-summary/types/CategorySummaryResponse';
import { ICategoryScoreCorrelationResponse, ITrendingTopicsResponse } from './widget-interface';
import { ICategoryDisplayedOverTimeResponse } from './categories-over-time-period/types/CategoryDisplayedOverTimeResponse';
import { ICategoryFrequencyScoreResponse } from './category-frequency-and-score-correlation/types/CategoryFrequencyScoreResponse';
const reviewOverTimeSampleData = require('./average-rating-over-time/SampleData.json');
import { IPerformanceTrendKpiResponse } from '../social-media/reports/performance-trend/type/IPerformanceTrendResponse';
import { ISurveyCompletionRate } from './survey-completion-rate/types/ISurveyCompletionRate';
import { String } from 'lodash';

declare const matchHeight: any;
const log = new Logger('Widget Service');
interface WidgetConfig {
  apiConfig: any;
  extra?: any;
}

@Injectable()
export class WidgetService implements OnDestroy {
  widgetsUrl = '/smwidget';
  npsSocreUrl = '/npsscore';
  kpisUrl = '/sm-kpis';
  dayofWeekUrl = '/daysofweek';
  asrwidgeturl = '/asrutilization';
  nissanwidgeturl = '/nissanuniversitycertification';
  reContactCustomers = '/re_contact_customers';
  staffperformancetrend = '/staffperformancetrend';
  reasonsbytechnician = '/reasonsbytechnician';
  employeedropdown = '/dealeremployees';
  isAllLoaded = false;
  vockpis = '/vockpi';
  kpiUrl = '/kpis/v2';
  dvburl = '/dealer_variable_bonus';
  frftServiceTechnicianurl = '/frftservicetechnician';
  top5opportunityurl = '/top5opportunity';

  frftq6no = '/get-model-Q6-No/frft-model-q6no';
  frftcustomercomments = '/frft_customer_comments';
  frftreasonsbytechnician = '/frft_reasons_by_technician';
  keyPerformanceIndicatorsUrl = '/keyperformanceindicators';
  recommendedActionsUrl = '/recommendedactions';
  customerCommentsUrl = '/customercomments';
  customerJourneyUrl = '/customerjurney';
  performanceTrendUrl = '/performanceTrend';
  performanceTrendQuestionUrl = '/performanceTrend/questions';
  performanceTrendUrlSM = '/performanceTrendSM';
  performanceTrendWidgetUrl = '/sm-reports';
  totalAlertsUrl = '/totalalerts';
  alertsEfficiencyUrl = '/alertsefficiency';
  surveyListWidgetUrl = '/surveylistwidget';
  surveytrendWidgetUrl = '/surveytrend';
  yearOverYearUrl = '/yearoveryear';
  reasonsEscalationsUrl = '/reasonsescalations';
  top5ResolutionsUrl = '/top5resolutions';
  topBottomPerformerUrl = '/topBottomPerfomer';
  topBottomPerformersHotAlertUrl = '/hotalerttopbottomperformers';
  keyperformance = '/keyperformance';
  delaerperformance = '/dealer_performance';
  surveycompletionrate = '/surveycompletionrate';
  sentimentCountUrl = '/sentimentcount';
  sentimentCountUrlSM = '/sentimentcount';
  regionalMapUrl = '/regionalmap';
  regionalMapUrlSM = '/regionalmap';
  trendingTopicsUrl = '/trendingtopics';
  trendingTopicsUrlSM = '/trendingtopics';
  trendingTopicSearchUrl = '/trendingtopicssearch';
  trendingTopicSearchUrlSM = '/trendingtopicssearch';
  categorytopicscommentsUrl = '/categorytopicscomments';
  commentsBusinessAreaUrl = '/commentsBusinessArea';
  sentimentAnalysisUrl = '/sentimentAnalysisUrl';
  reviewOverTimeUrl = '/smwidget';
  categoriesOverTimePeriodUrl = '/categorydisplayedovertime';
  categoriesOverTimePeriodUrlSM = '/categorydisplayedovertime';
  categorySummaryUrl = '/categorysummary';
  categorySummaryUrlSM = '/categorysummary';
  categoryScoreCorrelationUrl = '/categoryscorecorrelation';
  categoryScoreCorrelationStatsUrl = '/categoryscorecorrelationstats';
  categoryFrequencyScoreUrl = '/voc/categoryfrequencyscore';
  sentimentTrendUrl = '/sentimenttrend';
  sentimentTrendUrlSM = '/sentimenttrend';
  scoreUrl = '/scores';
  roPercDistributionUrl = '/ro_percentage';
  overallCSEUrl = '/overall_kpi_score';
  mpiPercYesUrl = '/mpi_yes_percentage';
  mpiYesVsNoUrl = '/mpi_yes_no';
  impactofMPIUrl = '/impact_of_mpi';
  mpiYesUrl = '/mpi_yes';
  mpiNoUrl = '/mpi_no';
  ocMaintYesUrl = '/oc_maintenance_yes';
  ocMaintNoUrl = '/oc_maintenance_no';
  frftOverviewUrl = '/frft_overview_widget';
  frftRootCausesUrl = '/frft-root-causes';
  frftServiceAdvisorTechnicianUrl = '/frft-service-advisor_technician';
  correctContactInfoOutstandingUrl = '/';
  asrUtilizationUrl = '/';
  nissanUniversityCertificateUrl = '/nissanuniversitycertification';
  dealerVariableBonus = '/dealer_variable_bonus';
  staffPerformanceTrendUrl = '/frft-staff-preformance-trend';
  frftReasonsByTechnicianUrl = '/frft-reasons-by-technician';
  fixedRightFirstTimeTrend = '/fixed_right_first_time_trend';
  performanceTrendWidget = '/performance_trend_widget';
  topbottomPerformerurl = '/dashboard_widget_top_bottom_performance_update';
  featureScroreUrl = '/feature_score_widget';
  widgetInterval: any;
  user = this.authService.getUser();
  update = new Subject<any>();
  $socialMediaTopStatusBarData = new Subject();

  constructor(
    private http: HttpClient,
    private filtersService: FiltersService,
    public widgetMapper: WidgetMapperService,
    private authService: AuthenticationService,
    public ngxPermission: NgxPermissionsService,
    private appConfig: AppConfigurationService
  ) {
    this.widgetInterval = setInterval(() => {
      if (this.isAllLoaded) {
        this.filtersService.filterDisabledStatus = false;
        const found = document.querySelectorAll('[data-can-render]');
        const results: any = [];
        found.forEach((element) => {
          const data = element.getAttribute('data-can-render');
          if (typeof data === 'string') {
            const p = data.trim().toLowerCase() === 'true';
            results.push(p);
          }
        });
        const anyFalse = results.find((x: boolean) => x === false);
        if (anyFalse === undefined && window.innerWidth > 1024) {
          matchHeight();
          this.filtersService.filterDisabledStatus = true;
        }
      }
    }, 1500);
  }
  ngOnDestroy(): void {
    if (this.widgetInterval) {
      clearInterval(this.widgetInterval);
    }
  }

  getKpis(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    if (config?.apiConfig?.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    return this.http.cache().disableLoader().post<Array<IKpisResponse>>(`${this.kpiUrl}`, filters);
  }

  getAreaOfImprovementData(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    const language = localStorage.getItem('language');
    if (config?.apiConfig?.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    return (
      this.http
        // .cache()
        .disableLoader()
        .post<RestMessage>(`${this.keyperformance}?languageCode=` + language, filters)
    );
  }
  gettop5opportunityurlData(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    const language = localStorage.getItem('language');
    if (config?.apiConfig?.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    return (
      this.http
        // .cache()
        .disableLoader()
        .post<RestMessage>(`${this.top5opportunityurl}?languageCode=` + language, filters)
    );
  }
  getDealerPerformance(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    if (Array.isArray(config.extra.events)) {
      filters.Event = config.extra.events.map((x: any) => x.Event).join(',');
      filters.SurveyVersion = config.extra.events.map((x: any) => x.value).join(',');
    }
    return (
      this.http
        // .cache()
        .disableLoader()
        .post<RestMessage>(`${this.delaerperformance}`, filters)
    );
  }
  getHeatMapUrl(previewId: number) {
    const languageCode = localStorage.getItem('language');
    const url = `/reporting/OrgSummaryGetSurveyQuestions/${previewId}/${languageCode}/${true}`;
    return url;
  }
  getHeatMap(previewId: number, config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `/reporting/org-summary/${previewId}/${languageCode}/${true}?isExport=true`;
    if (config?.apiConfig?.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    return this.http.disableLoader().post<any>(url, filters);
  }
  getSurveyCompletionRate(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    const url = `${this.surveycompletionrate}`;
    if (config?.apiConfig?.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    return this.http.disableLoader().post<ISurveyCompletionRate>(`${url}`, filters);
  }

  getTopBottomPerformer(config: WidgetConfig, name: string) {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.staffperformancetrend}${this.topbottomPerformerurl}/${languageCode}/${name}`;
    if (config?.apiConfig?.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    return this.http.cache().disableLoader().post<ITopBottomPerformerResponse>(`${url}`, filters);
  }

  getSmTopBottomPerformer(isTop: boolean, selectedHeaderTab: string) {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    if (selectedHeaderTab !== 'All') {
      filters.SourcesQuery = selectedHeaderTab;
    }
    return this.http
      .cache()
      .disableLoader()
      .post(`${this.widgetsUrl}/GetTopBottomPerformersSM/${isTop}/${selectedHeaderTab}`, filters);
  }

  getKeyPerformanceIndicators(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    if (config?.apiConfig?.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    return this.http
      .cache()
      .disableLoader()
      .post<Array<IKeyPerformanceIndicators>>(`${this.keyPerformanceIndicatorsUrl}`, filters);
  }

  getRecommendedActions(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    if (config?.apiConfig?.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    return (
      this.http
        // .cache()
        .disableLoader()
        .post<IRecommendedActionsResponse>(`${this.recommendedActionsUrl}`, filters)
    );
  }
  getCustomerJourney(key: string, value: string, config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    if (config?.apiConfig?.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    if (key && value) {
      return this.http
        .cache()
        .disableLoader()
        .post<ICustomerJourneyResponse>(`${this.customerJourneyUrl}/${key}/${value}`, filters);
    } else {
      return this.http.cache().disableLoader().post<ICustomerJourneyResponse>(`${this.customerJourneyUrl}`, filters);
    }
  }
  getCustomerJourneyCommentList(KpiName: string, LoyalityVal: string, Nps: string, pageSize: number, pageNo: number) {
    this.filtersService.setPaging(pageSize, pageNo);
    const filters = this.filtersService.getFilterObject();
    const Loyality = LoyalityVal;
    if (KpiName) {
      // console.log(`${this.customerJourneyUrl}/${KpiName}/${Loyality}/${Nps}`);
      return (
        this.http
          .cache()
          // .disableLoader()
          .post<RestMessage>(`${this.customerJourneyUrl}/${KpiName}/${Loyality}/${Nps}`, filters)
      );
    } else {
      return (
        this.http
          .cache()
          // .disableLoader()
          .post<RestMessage>(`${this.customerJourneyUrl}`, filters)
      );
    }
  }

  getNpsScore(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    if (config?.apiConfig?.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    const url = `${this.npsSocreUrl}`;
    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getCustomerComments(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    if (config?.apiConfig?.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    return this.http.cache().disableLoader().post<ICustomerCommentsResponse>(`${this.customerCommentsUrl}`, filters);
  }

  getPerformanceTrend(config: WidgetConfig, name: string) {
    // const filters = this.filtersService.getFilterObject();
    const filters = this.filtersService.getFilterObject();
    if (config?.apiConfig?.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    const url = `${this.performanceTrendUrl}/${name}`;
    return this.http.cache().disableLoader().post<Array<IPerformanceTrendResponse>>(`${url}`, filters);
  }
  getPerformanceTrendQuestion(config: WidgetConfig, name: string) {
    const filters = this.filtersService.getFilterObject();
    const url = `${this.performanceTrendQuestionUrl}/${name}`;
    return this.http.cache().disableLoader().post<Array<IPerformanceTrendQuestionResponse>>(`${url}`, filters);
  }

  getPerformanceTrendSM(param: any, compareItem: any) {
    const compareField = JSON.stringify(compareItem);
    // this.filtersService.setField(JSON.stringify(param));
    var fields = JSON.stringify(param);
    const filters = this.filtersService.getFilterObject();
    filters.Fields = fields;
    return this.http
      .cache()
      .disableLoader()
      .post<IPerformanceTrendKpiResponse>(
        `${this.performanceTrendWidgetUrl}/GetPerformanceTrendKpi/${compareField}`,
        filters
      );
  }

  getTotalAlerts(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    const url = `${this.totalAlertsUrl}`;
    if (config.apiConfig.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    return this.http.disableLoader().post<ITotalAlertResultResponse>(`${url}`, filters);
  }

  getCorrectContactInformation(config: WidgetConfig) {
    const languageCode = localStorage.getItem('language');
    const filters = this.filtersService.getFilterObject();
    const url = `${this.dayofWeekUrl}/correct_contact_info/${languageCode}`;
    return this.http.disableLoader().post<any>(`${url}`, filters);
  }

  getAlertsEfficiency() {
    const filters = this.filtersService.getFilterObject();
    const url = `${this.alertsEfficiencyUrl}`;
    return this.http.cache().disableLoader().post<Array<IAlertsEfficiencyResponse>>(`${url}`, filters);
  }

  getReasonsEscalations(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();

    const url = `${this.reasonsEscalationsUrl}`;
    if (config.apiConfig.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    return this.http.cache().disableLoader().post<Array<IReasonsEscalationsResponse>>(`${url}`, filters);
  }

  getTopBottomPerformersHotAlert() {
    const filters = this.filtersService.getFilterObject();
    const url = `${this.topBottomPerformersHotAlertUrl}`;
    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getTop5Resolutions(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    if (config.apiConfig.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    const url = `${this.top5ResolutionsUrl}`;
    return this.http.cache().disableLoader().post<Array<ITop5ResolutionsResponse>>(`${url}`, filters);
  }

  getSurveyListWidget(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    if (config.apiConfig.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    const url = `${this.surveyListWidgetUrl}`;
    return this.http.disableLoader().post<RestMessage>(`${url}`, filters);
  }
  getFRFTOverview() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.frftOverviewUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  getfrftRootCauses() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.frftRootCausesUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  getfrftServiceAdvisorTechnician(type: string) {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.frftServiceTechnicianurl}${this.frftServiceAdvisorTechnicianUrl}/${languageCode}/${type}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  getSurveyTrendWidget(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    if (config.apiConfig.SurveyId) {
      filters.Event = config.apiConfig.Event;
      filters.SurveyVersion = config.apiConfig.SurveyId;
    }
    const url = `${this.surveytrendWidgetUrl}`;
    return this.http.disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getYearOverYear(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    const url = `${this.yearOverYearUrl}`;
    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getAverageRatingData() {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http.cache().disableLoader().post(`${this.widgetsUrl}/GetAverageRatingOverTime`, filters);
  }

  getReviewOverTimeData() {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http.cache().disableLoader().post(`${this.widgetsUrl}/GetReviewOverTime`, filters);
  }

  getNotRespondedReviewsData(isNotResponded: boolean) {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http
      .cache()
      .disableLoader()
      .post(`${this.widgetsUrl}/GetNotRespondedReviews/` + isNotResponded, filters);
  }

  getSentimentTrendData(module: string, isDominant: boolean) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const url = `/${module}${this.sentimentTrendUrl + '/' + isDominant}`;
    return this.http.disableLoader().post<RestMessage>(`${url}`, filters);
  }
  getSentimentTrendDataForSM(module: string, isDominant: boolean) {
    const filters = this.filtersService.getFilterObjectForReviewList();
    const url = `/${module}${this.sentimentTrendUrlSM + '/' + isDominant}`;
    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getReviewsOverTimeData() {
    return reviewOverTimeSampleData;
  }

  getEmergingTrendData() {
    const filters = this.filtersService.getFilterObject();
    return this.http.post(`${this.widgetsUrl}/GetEmergingTrendsByCategory`, filters);
  }

  getRatingDistribution() {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http.cache().disableLoader().post(`${this.widgetsUrl}/GetRatingDistribution`, filters);
  }

  getCompetitiveLandscape() {
    const filters = this.filtersService.getFilterObject();
    return this.http.post(`${this.widgetsUrl}/GetCompetitiveLandscape`, filters);
  }

  getReviewSiteDistribution() {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http.cache().disableLoader().post(`${this.widgetsUrl}/GetReviewSitesDistribution`, filters);
  }

  getDealerProfile() {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http.cache().disableLoader().post(`${this.widgetsUrl}/getDealerProfile`, filters);
  }

  getCategoryFrequencyAndScore(sort: string, sentiment: string, kpiName: string) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    filters.SortColumn = sort;
    const url = `${this.categoryFrequencyScoreUrl}`;

    return this.http
      .cache()
      .disableLoader()
      .post<Array<ICategoryFrequencyScoreResponse>>(`${url}/${sentiment}/${kpiName}`, filters);
  }

  getSentimentCount(moduleName: string, isDominant: boolean = false) {
    let filters = null;
    filters = this.filtersService.getFilterObjectForVOCDashboard();

    // if (moduleName === 'voc') {
    // } else {
    //   filters = this.filtersService.getFilterObject();
    // }
    const url = `/${moduleName}${this.sentimentCountUrl + '/' + isDominant}`;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }
  getSentimentCountForSM(moduleName: string, isDominant: boolean = false) {
    let filters = null;
    filters = this.filtersService.getFilterObjectForReviewList();
    // if (moduleName === 'voc') {
    // } else {
    //   filters = this.filtersService.getFilterObject();
    // }
    const url = `/${moduleName}${this.sentimentCountUrlSM + '/' + isDominant}`;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getTrendingTopics(moduleName: string) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const url = `/${moduleName}${this.trendingTopicsUrl}`;

    return this.http.cache().disableLoader().post<Array<ITrendingTopicsResponse>>(`${url}`, filters);
  }
  getTrendingTopicsForSM(moduleName: string) {
    const filters = this.filtersService.getFilterObjectForReviewList();
    const url = `/${moduleName}${this.trendingTopicsUrlSM}`;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }
  getTrendingTopicSearch(moduleName: string, topic: any) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const url = `/${moduleName}${this.trendingTopicSearchUrl}/${topic}`;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }
  getTrendingTopicSearchForSM(moduleName: string, topic: any) {
    const filters = this.filtersService.getFilterObjectForReviewList();
    const url = `/${moduleName}${this.trendingTopicSearchUrlSM}/${topic}`;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }
  getTrendingTopicsCatFilter(moduleName: string, cat: any, lvl: number) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const url = `/${moduleName}${this.trendingTopicsUrl}`;

    filters.CategoryId = cat.Name;
    filters.CategoryName = cat.Label;
    filters.CategoryLevel = lvl + 1;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getCategoryTopicsAndComments(moduleName: string, cat: any, lvl: number, stab: any) {
    let filters;
    if (moduleName === 'voc') {
      filters = this.filtersService.getFilterObjectForVOCDashboard() as any;
    } else {
      filters = this.filtersService.getFilterObjectForTreeReportSM() as any;
    }

    const url = `/${moduleName}${this.categorytopicscommentsUrl}`;

    filters.CategoryId = cat.Name;
    filters.CategoryName = cat.Label;
    filters.CategoryLevel = lvl + 1;
    filters.SentimentType = stab;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getCommentsBusinessArea() {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const url = `${this.commentsBusinessAreaUrl}`;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getSentimentAnalysis() {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const url = `${this.sentimentAnalysisUrl}`;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getReviewOverTime() {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const url = `${this.reviewOverTimeUrl}`;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getCategoriesOverTimePeriod(moduleName: any, sentiment: any) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const url = `/${moduleName}${this.categoriesOverTimePeriodUrl}/${sentiment}`;

    return this.http.cache().disableLoader().post<ICategoryDisplayedOverTimeResponse>(`${url}`, filters);
  }
  getCategoriesOverTimePeriodForSM(moduleName: any, sentiment: any) {
    const filters = this.filtersService.getFilterObjectForReviewList();
    const url = `/${moduleName}${this.categoriesOverTimePeriodUrlSM}/${sentiment}`;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }
  public getRegionalMap(moduleName: string) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const url = `/${moduleName}${this.regionalMapUrl}`;
    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }
  public getRegionalMapForSM(moduleName: string) {
    const filters = this.filtersService.getFilterObjectForReviewList();
    const url = `/${moduleName}${this.regionalMapUrlSM}`;
    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getCategorySummary(moduleName: string) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const url = `/${moduleName}${this.categorySummaryUrl}`;

    return this.http.cache().disableLoader().post<ICategorySummaryResponse>(`${url}`, filters);
  }
  getCategorySummaryForSM(moduleName: string) {
    const filters = this.filtersService.getFilterObjectForReviewList();
    const url = `/${moduleName}${this.categorySummaryUrlSM}`;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getCategoryScoreCorrelation(moduleName: string, kpi: string) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const url = `/${moduleName}${this.categoryScoreCorrelationUrl}/${kpi}`;

    return this.http.cache().disableLoader().post<ICategoryScoreCorrelationResponse>(`${url}`, filters);
  }
  getKPIConfigurationData() {
    const opts = this.appConfig.getByIdentifier(Identifiers.KPIOptions);
    if (opts) {
      if (opts.ClientConfig === null) {
        opts.ClientConfig = opts;
      }
      if (opts.ClientConfig) {
        return opts.ClientConfig.Fields;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  getCommentListColumns() {
    const opts = this.appConfig.getByIdentifier(Identifiers.DynamicColunmsHides);
    if (opts) {
      if (opts.ClientConfig === null) {
        opts.ClientConfig = opts;
      }
      if (opts.ClientConfig) {
        return opts.ClientConfig.Fields;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  getCategoryScoreCorrelationStats(moduleName: string, kpi: string) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const url = `/${moduleName}${this.categoryScoreCorrelationStatsUrl}/${kpi}`;

    return this.http.cache().disableLoader().post<RestMessage>(`${url}`, filters);
  }

  getAverageReviewRatingKpi() {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http.cache().disableLoader().post(`${this.kpisUrl}/kpi_average_review_rating`, filters);
  }

  getReviewResponseRateKpi() {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http.cache().disableLoader().post(`${this.kpisUrl}/kpi_review_response_rate`, filters);
  }

  getReviewResponseTimeKpi() {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http.cache().disableLoader().post(`${this.kpisUrl}/kpi_review_response_time`, filters);
  }

  getStatusBarData() {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http.cache().disableLoader().post(`${this.kpisUrl}/kpi_stats`, filters);
  }

  getTotalReviewsKpi() {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http.cache().disableLoader().post(`${this.kpisUrl}/kpi_total_reviews`, filters);
  }

  getSocialPerformanceIndexKpiV2() {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http.cache().disableLoader().post(`${this.kpisUrl}/kpi_v2_social_performance_index`, filters);
  }

  getTopReviewSourcesKpiV2() {
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    return this.http.cache().disableLoader().post(`${this.kpisUrl}/kpi_v2_top_review_sources`, filters);
  }

  getReviewDetail(id: string) {
    const filters = this.filtersService.getFilterObject();
    return this.http.cache().post(`${this.widgetsUrl}/reviewdetail/` + id, filters);
  }

  getScores() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.scoreUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }

  getRoPercDistribution() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.roPercDistributionUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }

  getOverallCSE() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.overallCSEUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }

  getMPIPercYes() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.mpiPercYesUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }

  getMPIYesVsNo() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.mpiYesVsNoUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  getDealerVariableBonus() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.dvburl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  /* getFrftOverview() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.frftoverview}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }*/
  getFrftQ6no(type: string) {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.frftq6no}/${type}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  getFrftCustomerComments() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.frftcustomercomments}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  getFrftReasonsByTechnician() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.frftreasonsbytechnician}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }

  getImpactofMPI() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.impactofMPIUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }

  getMPIYes() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.mpiYesUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }

  getMPINo() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.mpiNoUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  getOCMaintYes() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.ocMaintYesUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  getOCMaintNo() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.ocMaintNoUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }

  getCategorySummaryKpi() {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    return this.http.cache().disableLoader().post(`${this.vockpis}/categorysummarykpi`, filters);
  }

  getTopPerformerKpi() {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    return this.http.cache().disableLoader().post(`${this.vockpis}/topperformerkpi`, filters);
  }

  getKpiSentimentSummary() {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    return this.http.cache().disableLoader().post(`${this.vockpis}/kpi_voc_sentiment_summary`, filters);
  }
  getKpiTrendingTopicsStats() {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    return this.http.cache().disableLoader().post(`${this.vockpis}/kpi_voc_trending_topics_Stats`, filters);
  }

  getAsrUtilization() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.asrwidgeturl}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }

  getFixedRightFirstTimeTrend() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.fixedRightFirstTimeTrend}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }

  getnissanUniversityCertificate(type: string) {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.nissanwidgeturl}/${type}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  getRecontactCustomers() {
    const filters = this.filtersService.getFilterObject();
    filters.SurveyVersion = JSON.parse(localStorage.getItem('surveyVersions'))
      .filter((ver: any) => ver.label === 'DG' && ver.Event === filters.Event)
      .map((ver: any) => ver.SurveyId)
      .join();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.reContactCustomers}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  /*getstaffPerformanceTrend(config: WidgetConfig, name: string) {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.staffPerformanceTrendUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }*/
  getstaffPerformanceTrend(name: string) {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.staffperformancetrend}${this.staffPerformanceTrendUrl}/${languageCode}/${name}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  getcorrectContactInfoOutstanding(config: WidgetConfig) {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.correctContactInfoOutstandingUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  fillEmployeeDropdown(dealer: string, code: string, level: number) {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.reasonsbytechnician}/${languageCode}/${dealer}/${code}/${level}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
  getreasonsbytechnician(processView: string, employeeName: string) {
    const filters = this.filtersService.getFilterObject();
    // const languageCode = localStorage.getItem('language');
    const url = `${this.reasonsbytechnician}${this.frftReasonsByTechnicianUrl}/${processView}/${employeeName}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }

  getFeaturedScore() {
    const filters = this.filtersService.getFilterObject();
    const languageCode = localStorage.getItem('language');
    const url = `${this.dayofWeekUrl}${this.featureScroreUrl}/${languageCode}`;
    return this.http.cache().disableLoader().post<Array<any>>(`${url}`, filters);
  }
}
